import React, {useState} from 'react';

import {pick} from 'lodash';
import {Box, Flex, Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {usePriceScenario} from '@renofi/api';
import {
  Toggle,
  Label,
  TextField,
  CurrencyField,
  SelectField,
  Button,
  Alert,
} from '@renofi/components';
import {creditScore, occupancyTypes} from '@renofi/api/src/enums';
import {darkGreen, fadeGreen} from '@renofi/utils/src/colors';

import {Page} from '../components';
import {leadVar, priceScenariosVar} from '../api/cache';
import onPriceScenariosFetch from '../api/callbacks/onPriceScenariosFetch';

import {ReactComponent as SmileIcon} from './img/smile.svg';
import PriceScenariosTable from './PriceScenariosTable';
import {Grid, Panel} from './styled';

const Rates = () => {
  const lead = useReactiveVar(leadVar);
  const priceScenarios = useReactiveVar(priceScenariosVar);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchPriceScenarios = usePriceScenario({
    onCompleted: onPriceScenariosFetch,
  });
  const hasOptions = Boolean(priceScenarios?.length);
  const [data, setData] = useState({
    ...pick(lead, [
      'brokerSourced',
      'creditScore',
      'firstMortgageBalance',
      'loanAmountDesiredForRenovation',
      'monthlyDebt',
      'monthlyIncome',
      'renovationCost',
    ]),
    secondMortgageBalance: lead.secondMortgageBalance || 0,
    occupancyType: lead.propertyUseType,
    afterRenovationValue: lead.afterRenovatedValue,
    currentHomeValue: lead.homeValue,
    creditScore: Number(
      creditScore[lead.creditScore].split('-')[1].replace('+', ''),
    ),
  });

  function onChange(key, value, err) {
    setData({...data, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function onSubmit() {
    setLoading(true);
    submit();
  }

  function forEachOccupancyTypes(callback) {
    return Object.entries(occupancyTypes).map(([key, value]) =>
      callback(key, value),
    );
  }

  async function submit() {
    await fetchPriceScenarios({
      ...data,
      loanAmount: lead.loanAmountDesiredForRenovation,
      loanPurpose: 'second-mortgage',
      propertyType: 'single-family',
      zipCode: String(lead.zipCode),
    });
    setLoading(false);
  }

  return (
    <Page>
      <h1>Rate calculator</h1>

      <Panel css={{marginTop: 0}} contentCss={{padding: '18px 24px'}}>
        <Grid>
          <Box>
            <Label
              error={errors.loanAmountDesiredForRenovation}
              small
              htmlFor="loanAmount">
              Loan amount needed
            </Label>
            <CurrencyField
              small
              icon
              error={errors.loanAmountDesiredForRenovation}
              value={data.loanAmountDesiredForRenovation}
              onChange={(value, err) =>
                onChange('loanAmountDesiredForRenovation', value, err)
              }
              id="loanAmountDesiredForRenovation"
              name="loanAmountDesiredForRenovation"
              type="text"
              required
            />
          </Box>

          <Box>
            <Label
              error={errors.currentHomeValue}
              small
              htmlFor="currentHomeValue">
              Estimated current home value
            </Label>
            <CurrencyField
              small
              icon
              error={errors.currentHomeValue}
              value={data.currentHomeValue}
              onChange={(value, err) =>
                onChange('currentHomeValue', value, err)
              }
              id="currentHomeValue"
              name="currentHomeValue"
              required
            />
          </Box>

          <Box>
            <Label
              error={errors.afterRenovationValue}
              small
              htmlFor="afterRenovationValue">
              Expected after renonvation value
            </Label>
            <CurrencyField
              small
              icon
              error={errors.afterRenovationValue}
              value={data.afterRenovationValue}
              onChange={(value, err) =>
                onChange('afterRenovationValue', value, err)
              }
              id="afterRenovationValue"
              name="afterRenovationValue"
              required
            />
          </Box>

          <Box>
            <Label small htmlFor="occupancyType">
              Occupancy type
            </Label>
            <SelectField
              small
              autoComplete="false"
              value={data.occupancyType}
              onChange={(value) => onChange('occupancyType', value)}
              id="occupancyType"
              name="occupancyType"
              required>
              {forEachOccupancyTypes((key, value) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </SelectField>
          </Box>

          <Box>
            <Label
              error={errors.firstMortgageBalance}
              small
              htmlFor="firstMortgageBalance">
              1st mortgage balance
            </Label>
            <CurrencyField
              small
              icon
              error={errors.firstMortgageBalance}
              value={data.firstMortgageBalance}
              onChange={(value, err) =>
                onChange('firstMortgageBalance', value, err)
              }
              id="firstMortgageBalance"
              name="firstMortgageBalance"
              required
            />
          </Box>

          <Box>
            <Label
              error={errors.secondMortgageBalance}
              small
              htmlFor="secondMortgageBalance">
              2nd mortgage balance
            </Label>
            <CurrencyField
              small
              icon
              error={errors.secondMortgageBalance}
              value={data.secondMortgageBalance}
              onChange={(value, err) =>
                onChange('secondMortgageBalance', value, err)
              }
              id="secondMortgageBalance"
              name="secondMortgageBalance"
              required
            />
          </Box>

          <Box>
            <Label error={errors.creditScore} small htmlFor="creditScore">
              FICO score
            </Label>
            <TextField
              small
              numbers
              error={errors.creditScore}
              value={data.creditScore}
              onChange={(value, err) => onChange('creditScore', value, err)}
              id="creditScore"
              name="creditScore"
              type="text"
              required
              inputmode="numeric"
              pattern={'[0-9]*'}
            />
          </Box>

          <Box>
            <Button
              css={{width: 180, paddingRight: [24, 24]}}
              mt={24}
              small
              onClick={onSubmit}
              loading={loading}>
              {loading ? 'Loading' : 'Calculate rates'}
            </Button>{' '}
          </Box>
        </Grid>

        <Toggle show={hasOptions}>
          <Box mt={30}>
            <Alert bgColor={fadeGreen} border={fadeGreen} color={darkGreen}>
              <Flex alignItems="center">
                <SmileIcon />
                <Text ml={12}>
                  {priceScenarios.length} qualifying loan products found based
                  on the details entered above.
                </Text>
              </Flex>
            </Alert>
          </Box>
        </Toggle>
      </Panel>

      <Toggle show={hasOptions}>
        <Panel contentCss={{padding: 0}}>
          <PriceScenariosTable priceScenarios={priceScenarios} />
        </Panel>
      </Toggle>
    </Page>
  );
};

export default Rates;

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

import {OptionItemText} from '../styled';

const OptionItem = ({label, value, children}) => {
  return (
    <Flex width={1} alignItems="center" minHeight={45}>
      <OptionItemText flex={100} css={{lineHeight: '12px'}} pr={32}>
        {label}
      </OptionItemText>
      <OptionItemText flex={94} bold>
        {children || value}
      </OptionItemText>
    </Flex>
  );
};

OptionItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

export default OptionItem;

import React, {useEffect, useState} from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {getEnvironmentUrl, useScreenSize} from '@renofi/utils';
import {Loader, Toggle} from '@renofi/components';
import {lightGray} from '@renofi/utils/src/colors';

import {Page} from '../components';
import {leadVar} from '../api/cache';

const DEFAULT_HEIGHT = 950;
const isTest = process.env.REACT_APP_ENV === 'test';

const Calculator = ({active, initialProductId}) => {
  const [ready, setReady] = useState(false);
  const history = useHistory();
  const {isDesktop, isMobile} = useScreenSize();
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const lead = useReactiveVar(leadVar);
  const {calculatorToken} = lead;
  const host = getEnvironmentUrl('calculator', '4005');
  const productPath = initialProductId ? `/product/${initialProductId}` : '';
  const url = `${host}/renovation/${lead.calculatorToken}${productPath}?embed=true`;

  useEffect(() => {
    if (calculatorToken && active) {
      sendEvent('Dashboard/Calculator-Page');
      document.body.classList.add('calculator');
    }

    if (!active) {
      document.body.classList.remove('calculator');
    }
  }, [calculatorToken, active]);

  useEffect(() => {
    if (!calculatorToken) return;
    if (isTest) return setReady(true);

    setTimeout(() => {
      setReady(true);
    }, 1500);
  }, [calculatorToken]);

  useEffect(() => {
    if (initialProductId) {
      history.push(`/${lead.id}/calculator`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessage);
  }, []);

  function onMessage(event) {
    if (event?.origin !== host) return;
    const {iFrameHeight, sliderValue} = event?.data || {};

    if (iFrameHeight) {
      setHeight(iFrameHeight);
    }

    if (sliderValue) {
      sendEvent('Dashboard/Calculator-Value-Change', {
        calculatorValues: event.data,
      });
    }
  }

  return (
    <Page
      pb={isMobile ? 0 : undefined}
      showCalculatorDisclaimer={isDesktop}
      noPadding={isMobile}
      css={{
        display: active ? 'block' : 'none !important',
      }}>
      <Toggle show={isDesktop}>
        <h1>Personalized monthly payment calculator</h1>
      </Toggle>
      <Toggle show={!ready}>
        <Flex
          css={{width: '100%', paddingTop: 40}}
          alignItems="center"
          justifyContent="center">
          <Loader color={lightGray} size={2} />
        </Flex>
      </Toggle>
      <iframe
        style={{
          height: isMobile ? '100%' : height + 80,
          transformOrigin: 'top left',
          ...(isDesktop ? {transform: 'scale(0.75)'} : {}),
          transition: '.2s',
          opacity: ready ? 1 : 0,
          border: 'none',
          width: '100%',
          maxWidth: 1020,
          marginBottom: isMobile ? 0 : -280,
        }}
        src={url}
        title="RenoFi calculator"
      />
    </Page>
  );
};

export default Calculator;

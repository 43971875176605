const FULL_DAY = 24 * 60 * 60 * 1000;

export default function (numberOfDays, since, periodEnd = new Date()) {
  if (!since) {
    return false;
  }

  const periodStart = since instanceof Date ? since : new Date(since);
  const diff = periodEnd - periodStart;
  const diffAsDays = Math.floor(diff / FULL_DAY);

  return diffAsDays >= numberOfDays;
}

import {useState} from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import {
  isEmpty,
  isNil,
  isObject,
  mapKeys,
  isNull,
  isUndefined,
  omitBy,
} from 'lodash';
import {Text, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {Button, Form, Label, PhoneField, TextField} from '@renofi/components';
import {useContractorFind} from '@renofi/api';
import {darkGreen, gray} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';
import {capitalizeFirstLetter} from '@renofi/utils/src/format';
import useSubmitContractorSearch from '@renofi/api/src/hooks/useSubmitContractorSearch';

import {onContractorSearch} from '../../api/callbacks';
import {ReactComponent as TickIcon} from '../img/tick.svg';
import {ReactComponent as VerifiedIcon} from '../img/verified.svg';
import {FormRow, InputWrapper} from '../styled';
import {contractorVar, leadVar} from '../../api/cache';
import ContractorEmpty from '../ContractorEmpty';

const ContractorsForm = ({setLoading, loading}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const lead = useReactiveVar(leadVar);

  const contractorFind = useContractorFind({
    onCompleted: onContractorSearch,
  });
  const {submitContractorSearch} = useSubmitContractorSearch();
  const contractor = useReactiveVar(contractorVar);

  function onChange(key, value, err) {
    if (submitted) setSubmitted(false);
    setFormData({...formData, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function isValid(item) {
    return !isNull(item) && !isUndefined(item) && item !== '';
  }

  function isFormValid() {
    return isValid(formData.businessName);
  }

  async function onSubmit() {
    setLoading(true);
    setSubmitted(true);

    const payload = omitBy(formData, (value) => !value);
    sendEvent('Dashboard/Contractor-Search-Clicked', {
      ...mapEventProps(payload),
    });

    try {
      const rsp = await contractorFind({
        filter: payload,
      });
      const errors = rsp?.errors;
      const contractor = rsp?.data?.contractorFind;

      if (errors?.length > 0 && isNil(contractor)) {
        captureException(errors);
        sendEvent('Dashboard/Contractor-Search-Error', {
          gcSearchResponse: errors,
        });
        setLoading(false);
        return;
      }

      sendEvent('Dashboard/Contractor-Search', {
        ...mapEventProps(payload),
        gcSearchResponse: contractor,
      });

      setLoading(false);
    } catch (error) {
      captureException();
      setLoading(false);
    }

    await submitContractorSearch({
      variables: {
        ...payload,
        ...(contractor?.id ? {contractorId: contractor?.id} : {}),
        scenarioId: lead.id,
      },
    });
  }

  function captureException() {
    Sentry.captureException(
      new Error('Dashboard app - Contractor search error'),
    );
  }

  function mapEventProps(object) {
    return mapKeys(
      object,
      (value, key) => `gcSearch${capitalizeFirstLetter(key)}`,
    );
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <FormRow>
          <InputWrapper mb={[24, 0]}>
            <Label small htmlFor="businessName">
              Contractor company name
            </Label>
            <TextField
              autoFocus
              placeholder="Business name"
              required
              small
              value={formData.businessName}
              onChange={(value, err) => onChange('businessName', value, err)}
              id="businessName"
              name="businessName"
              type="text"
            />
          </InputWrapper>

          <InputWrapper mb={[24, 0]}>
            <Label small htmlFor="website">
              Contractor company website
            </Label>
            <TextField
              placeholder="Website"
              small
              value={formData.website}
              onChange={(value, err) => onChange('website', value, err)}
              id="website"
              name="website"
              type="text"
            />
          </InputWrapper>

          <InputWrapper />
        </FormRow>

        <FormRow mt={[0, 24]}>
          <InputWrapper mb={[24, 0]}>
            <Label small htmlFor="email">
              Contractor email
            </Label>
            <TextField
              placeholder="Email"
              email
              small
              value={formData.email}
              onChange={(value, err) => onChange('email', value, err)}
              id="email"
              name="email"
              type="text"
            />
          </InputWrapper>

          <InputWrapper mb={[24, 0]}>
            <Label small htmlFor="phoneNumber">
              Contractor phone
            </Label>
            <PhoneField
              small
              value={formData.phoneNumber}
              onChange={(value, err) => onChange('phoneNumber', value, err)}
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
            />
          </InputWrapper>
          <InputWrapper>
            <Button
              type="submit"
              secondary
              loading={loading}
              disabled={!isFormValid() || loading}
              small>
              Search contractor
            </Button>
          </InputWrapper>
        </FormRow>
      </Form>

      {isObject(contractor) && isEmpty(contractor) && (
        <Text mt={36} color={gray}>
          <Box as="span" mr="6px" verticalAlign={-2}>
            <TickIcon />
          </Box>
          <em>Enter contractor details above to check vetting status</em>
        </Text>
      )}

      {!isEmpty(contractor) && (
        <Text mt={36} color={darkGreen}>
          <Box as="span" mr="6px" verticalAlign={-2}>
            <VerifiedIcon />
          </Box>
          This contractor has completed projects with RenoFi!
        </Text>
      )}

      {isNil(contractor) && submitted && !loading && (
        <ContractorEmpty businessName={formData.businessName} />
      )}
    </>
  );
};

ContractorsForm.propTypes = {
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ContractorsForm;

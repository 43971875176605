import {useEffect} from 'react';

import {useHistory} from 'react-router-dom';

const useOnBrowserAction = (callback) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action === 'POP') {
        callback();
      }
    });

    return () => {
      unlisten();
    };
  }, []);
};

export default useOnBrowserAction;

import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {hasCRMOwner, useSrLO} from '@renofi/utils';

import {crmOwnerVar} from '../../api/cache';

const AdvisorName = ({size = 18, ...props}) => {
  const crmOwner = useReactiveVar(crmOwnerVar);
  const srLO = useSrLO();

  function getName() {
    if (srLO?.firstName && srLO?.lastName)
      return `${srLO.firstName} ${srLO.lastName}`;
    if (hasCRMOwner(crmOwner))
      return `${crmOwner.firstName} ${crmOwner.lastName}`;
    return 'Lee Miller';
  }

  return (
    <Text fontSize={size} {...props}>
      {getName()}
    </Text>
  );
};

AdvisorName.propTypes = {
  size: PropTypes.number,
};

export default AdvisorName;

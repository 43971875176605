import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Panel as PanelComponent} from '@renofi/components';

export const Panel = styled(PanelComponent)(
  mediaQuery({
    boxShadow: 'none !important',
    backgroundColor: 'white',
  }),
);

export const MapZipCode = styled(Text)({
  backgroundColor: '#fff',
  padding: '8px',
  fontSize: 32,
  lineHeight: '28px',
  fontWeight: 700,
  position: 'absolute',
  bottom: 0,
  marginLeft: '50%',
  transform: 'translateX(-50%)',
  borderRadius: 8,
});

export const ContractorStatusCard = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    padding: [['20px 10px'], 20],
    borderRadius: 8,
  }),
);

import {useReactiveVar} from '@apollo/client';

import {usePrevious} from '@renofi/utils';

import {modalVar, setModal} from '../api/cache';

export const MODAL_TYPES = {
  NONE: 'none',
  SCHEDULE: 'loan_options_cta_schedule_call',
  READY: 'loan_options_cta_schedule_call_ready',
  NOT_READY: 'loan_options_cta_schedule_call_not_ready',
  LEAD_DETAILS: 'loan_options_cta_schedule_call_ready_ld',
  FEEDBACK: 'loan_options_feedback_modal',
  SKIP_PREQUALIFICATION: 'loan_options_cta_schedule_call_skip',
  REQUEST_PREQUALIFICATION: 'loan_options_cta_schedule_call_request',
  DESIGN_LEAD_DETAILS: 'design_lead_details',
  SCHEDULE_DESIGN_CALL: 'schedule_design_call',
};

export default function useModals() {
  const modal = useReactiveVar(modalVar);
  const prevModal = usePrevious(modal);

  return {
    setModal,
    modal,
    prevModal,
  };
}

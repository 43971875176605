import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {Page, Title} from '../components';

import HomeValuesPanel from './HomeValuesPanel';

const Home = ({loading}) => {
  useEffect(() => sendEvent('Dashboard/Home-Value-Page'), []);

  return (
    <Page showFooterLinks showHomeValueDisclaimer loading={loading}>
      <Title>Project estimate</Title>
      <HomeValuesPanel />
    </Page>
  );
};

Home.propTypes = {
  loading: PropTypes.bool,
};

export default Home;

import {Flex, Image, Text} from 'rebass';

import lockIcon from '@renofi/components/src/Icons/lock.svg';

import cloudflareIcon from '../img/cloudflare.svg';

const SecuritySeal = ({css}) => (
  <Flex alignItems="start" css={{gap: 20, marginTop: 59, ...css}}>
    <Image minWidth={23} width={23} height={26} src={lockIcon} />
    <Text fontSize={12} lineHeight="18px" color="#737373" textAlign="center">
      We value your privacy. We are committed to securely storing all customer
      data and will never share it with unauthorized parties.
    </Text>
    <Image minWidth={57} width={57} height={36} src={cloudflareIcon} />
  </Flex>
);

export default SecuritySeal;

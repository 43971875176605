import {NetworkStatus, useLazyQuery, useQuery} from '@apollo/client';

export default (query, {lazy = false, ...options} = {}) => {
  const hook = lazy ? useLazyQuery : useQuery;
  let response = hook(query, {...options, notifyOnNetworkStatusChange: true});

  if (lazy) {
    const [fetch, rsp] = response;
    const refetching = rsp?.networkStatus === NetworkStatus.refetch;
    response = {
      fetch: (p) => {
        return fetch(p);
      },
      refetching,
      ...rsp,
    };
  } else {
    const {data, error, loading, networkStatus, refetch} = response;
    const refetching = networkStatus === NetworkStatus.refetch;
    response = {data, error, loading, fetch: refetch, refetch, refetching};
  }

  return response;
};

import React, {useState, useEffect} from 'react';

import {Box, Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';

import {toCurrency} from '@renofi/utils/src/format';
import {lightGray, red} from '@renofi/utils/src/colors';
import {Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Column, Paragraph, RenovateDot, RenovationLabel} from '../Chart/styled';
import {leadVar} from '../../api/cache';
import {ValueItem} from '../../components';
import Chart from '../Chart';

export const TOTAL_YEARS = 15;

function generateValues(firstValue) {
  const array = [firstValue];
  for (let i = 0; i < TOTAL_YEARS; i++) {
    array.push(Math.round(array[i] * 1.02));
  }
  return array;
}

const HomeValues = ({loading, storedValue}) => {
  const {renovationCost, estimatedHomeValue} = useReactiveVar(leadVar);
  const value = storedValue || estimatedHomeValue;
  const {isDesktop, isMobile} = useScreenSize();
  const [baseValues, setBaseValues] = useState([]);
  const [renofiValues, setRenofiValues] = useState([]);
  const [year, setYear] = useState(1);

  let roi = 0.912;

  const afterRenovatedValue = value + renovationCost * roi;

  useEffect(() => {
    setBaseValues(generateValues(value));
    setRenofiValues(generateValues(afterRenovatedValue));
  }, [value, afterRenovatedValue]);

  const manualCopy = `Your manually entered home value is $${toCurrency(
    storedValue,
  )}`;

  return (
    <>
      <Column mr={[0, 48]} minWidth={300}>
        <ValueItem
          mb={[36, 0]}
          loading={loading}
          value={value}
          title="Estimated Current Property Value"
          tooltipText={`This is an estimation based on a variety of data sources. ${
            storedValue ? manualCopy : ''
          }`}
        />
        <Toggle show={isMobile}>
          <ValueItem
            value={afterRenovatedValue}
            title="Estimated After Renovation Value"
            tooltipText="After renovation value (also known as after repair value) is the value of a home after you’ve completed renovations."
          />
        </Toggle>
        <Box maxWidth={['100%', 240]} mt={40}>
          <Paragraph mb={[0, 24]} fontSize={16}>
            See how your property value changes over time
          </Paragraph>
          <Box>
            <Text
              css={{
                borderBottom: `solid 1px ${lightGray}`,
                paddingBottom: '8px',
              }}>
              {!year ? 'Today' : `At year ${year}`}
            </Text>
            <RenovationLabel>
              <Paragraph>
                <RenovateDot />
                With renovation
              </Paragraph>
              <Paragraph>${toCurrency(renofiValues[year]) || ''}</Paragraph>
            </RenovationLabel>

            <RenovationLabel>
              <Paragraph>
                <RenovateDot color={red} />
                Without renovation
              </Paragraph>
              <Paragraph>${toCurrency(baseValues[year]) || ''}</Paragraph>
            </RenovationLabel>
          </Box>
        </Box>
      </Column>
      <Column mt={[24, 0]} flex={1}>
        <Toggle show={isDesktop}>
          <ValueItem
            value={afterRenovatedValue}
            title="Estimated After Renovation Value"
            tooltipText="After renovation value (also known as after repair value) is the value of a home after you’ve completed renovations."
          />
        </Toggle>
        <Box width="100%" pl={28} pb={[20, 0]}>
          <Chart
            year={year}
            onChange={setYear}
            baseValues={baseValues}
            renofiValues={renofiValues}
          />
        </Box>
      </Column>
    </>
  );
};

HomeValues.propTypes = {
  isManual: PropTypes.bool,
  loading: PropTypes.bool,
};

export default HomeValues;

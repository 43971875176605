import React from 'react';

import {noop} from 'lodash';
import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {sendEvent} from '@renofi/analytics';

import {CalendarIcon} from '../Icons';
import Button from '../Button';

import {darkIconStyles} from './styled';

const ScheduleButton = ({
  secondary,
  isSticky,
  text = 'Schedule a call with advisor',
  icon = (
    <CalendarIcon style={{marginLeft: -15, marginRight: 10, marginTop: -5}} />
  ),
  analyticsPrefix = 'POS',
  readyToSchedule,
  css = {},
  onClick = noop,
  ...props
}) => {
  function onScheduleClick() {
    onClick();
    sendEvent(`${analyticsPrefix}/Schedule-Button-Click`, {
      scheduleButtonText: text,
      readyToSchedule: readyToSchedule,
    });
    sendEvent(
      `${analyticsPrefix}/Schedule-Button-Click-${
        isSticky ? 'Sticky-Footer' : 'Hero'
      }`,
    );
  }

  return (
    <Button
      large
      secondary={secondary}
      noCaps
      onClick={onScheduleClick}
      css={{
        marginBottom: 16,
        ...(secondary ? darkIconStyles : {}),
        ...css,
      }}
      {...props}>
      <Flex justifyContent="center" alignItems="center">
        {icon}
        {text}
      </Flex>
    </Button>
  );
};

ScheduleButton.propTypes = {
  secondary: PropTypes.bool,
  isSticky: PropTypes.bool,
  css: PropTypes.object,
  text: PropTypes.string,
  icon: PropTypes.node,
  readyToSchedule: PropTypes.string,
  analyticsPrefix: PropTypes.string,
  onClick: PropTypes.func,
};

export default ScheduleButton;

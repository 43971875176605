import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {FooterLinks, Toggle} from '@renofi/components';
import {ProductOptionsDisclaimer} from '@renofi/modules';
import {navbarBreakpoint, useScreenSize} from '@renofi/utils';

import {Loader} from '../../components';
import {loanProductsVar} from '../../api/cache';

const Page = ({
  showOptionsDisclaimer,
  showCalculatorDisclaimer,
  showHomeValueDisclaimer,
  showFooterLinks,
  loading,
  noPadding,
  children,
  css,
  pb = 80,
}) => {
  const loanProducts = useReactiveVar(loanProductsVar);
  const {isMobile} = useScreenSize(navbarBreakpoint);

  return (
    <>
      <Toggle show={loading}>
        <Loader />
      </Toggle>

      <Toggle show={!loading}>
        <Flex
          justifyContent="space-between"
          css={css}
          width="100%"
          maxWidth={1200}
          minHeight={'100%'}
          flexDirection="column"
          p={noPadding ? 0 : isMobile ? '24px 8px' : 36}
          pb={pb}>
          <Box height="100%">
            {children}

            <Toggle show={showOptionsDisclaimer}>
              <ProductOptionsDisclaimer loanProducts={loanProducts} />
            </Toggle>

            <Toggle show={showCalculatorDisclaimer}>
              <Text pt={30} fontSize={12} lineHeight="15px">
                <strong>Disclaimer</strong>: This is not an official loan
                estimate. Get an official loan estimate before choosing a loan.
                Your actual rate, payment, and costs could be higher. RenoFi is
                not a lender, rather we’ve partnered with lenders that leverage
                RenoFi’s technology to seamlessly provide RenoFi Loans. The
                rates, monthly payments, and terms shown are illustrative of
                products offered by RenoFi partner lenders but may not be
                available in all locations - apply with a RenoFi partner lender
                to obtain an official loan estimate.
              </Text>
            </Toggle>
          </Box>

          <Toggle
            show={
              showCalculatorDisclaimer ||
              showOptionsDisclaimer ||
              showFooterLinks
            }>
            <Toggle show={showHomeValueDisclaimer}>
              <Text pt={30} fontSize={12} lineHeight="15px">
                <strong>Disclaimer</strong>: Home value estimates are provided
                by third party automated valuation model and, based on current
                market factors. The values are estimates only and should only be
                used as a helpful tool. These values are not guarantees of your
                home value, are subject to change, and could be different than
                the valuation models and methods used by RenoFi’s
                partner-lenders.
              </Text>
            </Toggle>
            <FooterLinks center />
          </Toggle>
        </Flex>
      </Toggle>
    </>
  );
};

Page.propTypes = {
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  css: PropTypes.object,
  pb: PropTypes.number,
  children: PropTypes.node,
  showOptionsDisclaimer: PropTypes.bool,
  showCalculatorDisclaimer: PropTypes.bool,
  showHomeValueDisclaimer: PropTypes.bool,
  showFooterLinks: PropTypes.bool,
};

export default Page;

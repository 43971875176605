import React from 'react';

import PropTypes from 'prop-types';

import {red} from '@renofi/utils';

import {Message as ErrorMessage} from '../TextField';

import {Textarea as StyledTextarea} from './styled';

const Textarea = ({error, errorCss, onChange, ...props}) => {
  function onChangeValue(event) {
    onChange(event.target.value);
  }

  return (
    <div style={{position: 'relative'}}>
      <StyledTextarea error={error} onChange={onChangeValue} {...props} />
      {error ? (
        <ErrorMessage color={red} css={errorCss}>
          {error}
        </ErrorMessage>
      ) : null}
    </div>
  );
};

Textarea.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  errorCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  onChange: PropTypes.func,
};

export default Textarea;

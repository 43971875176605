import React from 'react';

import {Flex, Box, Text} from 'rebass';
import PropTypes from 'prop-types';

import {SkeletonText} from '@renofi/components';
import {darkGreen, gray, yellow} from '@renofi/utils/src/colors';

const DetailBox = ({icon, title, value, iconOffset = 0}) => {
  const formattedValue = formatValue(value);
  const Icon = icon;

  function formatValue(value) {
    if (
      value === null ||
      value === 'consider' ||
      value === 'fault' ||
      value === false
    )
      return 'Not listed';
    if (value === 'pass' || value === true) return 'Pass';
    return value;
  }

  return (
    <Flex
      mx="8px"
      minWidth={90}
      maxWidth={['100%', 120]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Box ml={iconOffset}>
        <Icon />
      </Box>
      <Text color={gray} mb="-5px">
        {title}
      </Text>
      <SkeletonText
        fontSize={14}
        fontWeight={800}
        skeletonHeight={12}
        skeletonWidth={30}
        color={formattedValue === 'Not listed' ? yellow : darkGreen}>
        {formattedValue}
      </SkeletonText>
    </Flex>
  );
};

DetailBox.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  iconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DetailBox;

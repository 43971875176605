import styled from '@emotion/styled/macro';
import {Flex, Box, Image, Text} from 'rebass';

import {darkGray} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

import arrows from './img/arrows.svg';

export const RenovateDot = styled(Image)(
  mediaQuery({
    height: 11,
    width: 11,
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: '6px',
  }),
  ({color}) => ({
    background: color || '#13C427',
  }),
);

export const RenovationLabel = styled(Flex)(
  mediaQuery({
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: '35px',
    paddingTop: 10,
  }),
);

export const Column = styled(Box)(
  mediaQuery({
    flexDirection: 'column',
    '.c3 > svg': {},
    '.chart-wrapper': {
      borderBottom: 'solid 1px #CCCCCC',
    },
  }),
);

export const LegendLeft = styled(Flex)(
  mediaQuery({
    borderRight: 'solid 1px #CCCCCC',
    fontSize: 12,
    textAlign: 'right',
    fontWeight: 100,
    justifyContent: 'space-between',
    position: 'absolute',
    left: -80,
    paddingRight: 8,
    top: 0,
    width: 80,
    height: 128,
    flexDirection: 'column',
  }),
);

export const LegendBottom = styled(Flex)(
  mediaQuery({
    width: '100%',
    justifyContent: 'space-between',
    fontSize: 12,
    fontWeight: 100,
    marginBottom: -30,
    paddingRight: '5px',
    height: 30,
  }),
  ({blockWidth}) => ({
    paddingLeft: blockWidth - 2,
  }),
);

export const Paragraph = styled(Text)(
  mediaQuery({
    fontSize: 14,
    lineHeight: '21px',
    color: darkGray,
    fontWeight: 100,
  }),
  ({fontSize}) => mediaQuery({fontSize}),
);

export const Marker = styled(Box)(
  mediaQuery({
    position: 'absolute',
    width: 9,
    height: 9,
    zIndex: 99,
    borderRadius: 100,
    backgroundColor: 'white',
  }),
  ({x, y, color, shadowColor}) => ({
    boxShadow: `0px 0px 0px 2px ${shadowColor}`,
    border: `solid 2px ${color}`,
    left: `${x - 8}px`,
    top: `${y}px`,
    transform: 'scale(1, 1)',
    transition: 'transform .2s',
    '&.animate': {
      transform: 'scale(1.4, 1.4)',
    },
  }),
);

export const ChartLabel = styled(Text)(
  mediaQuery({
    fontSize: 14,
    width: 50,
    ':after': {
      content: '" "',
      height: 128,
      width: 2,
      background: 'black',
      display: 'block',
      marginLeft: 17,
    },
    ':before': {
      position: 'absolute',
      content: '" "',
      height: 34,
      left: -2,
      top: 70,
      width: 100,
      display: 'block',
      background: `url(${arrows}) no-repeat`,
      backgroundPositionY: 20,
    },
  }),
);

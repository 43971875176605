import {Flex, Text} from 'rebass';
import PropTypes from 'prop-types';

import {kylieImage, mimiImage, leeImage} from '../Icons';

import Advisors from './img/otherAdvisors.svg';
import {AvatarImage, StaticImagesFlex} from './styled';

function AssistantsStaticWidget(props) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <StaticImagesFlex>
        <Flex justifyContent="center">
          <AvatarImage {...props} src={mimiImage} />
          <AvatarImage {...props} src={leeImage} />
          <AvatarImage {...props} src={kylieImage} />
          <AvatarImage {...props} src={Advisors} />
        </Flex>
      </StaticImagesFlex>
      <Text mt={10} fontSize={12} color="#777">
        We’re here to help
      </Text>
    </Flex>
  );
}

AssistantsStaticWidget.propTypes = {
  onClick: PropTypes.func,
  isFirstPage: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

export default AssistantsStaticWidget;

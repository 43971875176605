import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {Button, Modal} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import {setLead} from '../../api/cache';

import {EditScenarioModalWrapper} from './styled';

const EditScenarioModal = ({id, show, onClose}) => {
  const history = useHistory();

  useEffect(() => {
    if (show) sendEvent('Dashboard/Loan-Options-Edit-Details-Modal');
  }, [show]);

  function onOpenCalculator() {
    sendEvent('Dashboard/Loan-Options-Edit-Go-To-Calculator-Clicked');
    history.push(`/${id}/calculator`);
  }

  function onChangeDetails() {
    sendEvent('Dashboard/Loan-Options-Edit-Change-Project-Clicked');
    setLead({editProject: true});
    onClose();
  }

  function onModalClose() {
    sendEvent('Dashboard/Edit-Details-Modal-Closed');
    onClose();
  }

  return (
    <Modal width={445} light show={show} onClose={onModalClose}>
      <EditScenarioModalWrapper>
        <Text fontSize={24} mb={24}>
          Want to try different scenarios?
        </Text>
        <Button onClick={onOpenCalculator} width={300} mb={24}>
          Go to calculator
        </Button>
        <Text fontSize={16} mb={24}>
          or
        </Text>
        <Button
          data-testid="change-project-btn"
          secondary
          onClick={onChangeDetails}
          width={300}>
          Change project details
        </Button>
      </EditScenarioModalWrapper>
    </Modal>
  );
};

EditScenarioModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default EditScenarioModal;

import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {useFlag} from '@renofi/utils/src/feature-flags';
import {sendEvent} from '@renofi/analytics';
import {useScreenSize, isLeadQualified} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {leadVar} from '../api/cache';
import {Page, Panel, Title} from '../components';

import ContractorsForm from './ContractorsForm';
import ContractorsDetails from './ContractorsDetails';
import ScheduleBox from './ScheduleBox';

const ContractorCheck = () => {
  const {isDesktop} = useScreenSize();
  const [loading, setLoading] = useState(false);
  const lead = useReactiveVar(leadVar);
  const isQualified = isLeadQualified(lead);
  const isScheduleEnabled = useFlag('dashboard-contractors-search-schedule');

  useEffect(() => sendEvent('Dashboard/Contractor-Check-Page'), []);

  return (
    <Page showFooterLinks>
      <Title>Check your contractor</Title>
      <Text
        fontSize={[18, 24]}
        lineHeight={['28px', '36px']}
        mt={24}
        fontWeight={100}>
        Check if the contractor you’re considering has completed projects with
        RenoFi.
      </Text>

      <Panel>
        <ContractorsForm setLoading={setLoading} loading={loading} />
        <ContractorsDetails loading={loading} />
      </Panel>

      <Toggle show={isDesktop && isQualified && isScheduleEnabled}>
        <Panel>
          <ScheduleBox />
        </Panel>
      </Toggle>
    </Page>
  );
};

ContractorCheck.propTypes = {
  propName: PropTypes.string,
};

export default ContractorCheck;

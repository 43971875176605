import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {darkGray} from '@renofi/utils/src/colors';

export const EditScenarioModalWrapper = styled(Box)(() => ({
  color: darkGray,
  flexDirection: 'column',
  width: '100%',
  textAlign: 'center',
  padding: '42px 0',
}));

import {Box, Flex, Text, Link} from 'rebass';

import {Alert, CalendarSuccessIcon} from '@renofi/components';

const ScheduledCallAlert = ({
  callDetails,
  advisor = 'RenoFi Advisor',
  rescheduleEnabled,
  onReschedule,
}) => (
  <Flex justifyContent="center" alignItems="center" marginBottom="20px">
    <Box width={['100%', '80%']}>
      <Alert bgColor="#E7F9E9" color="#0B7517" border="1px solid #13C427">
        <Flex alignItems="center">
          <CalendarSuccessIcon style={{width: 48, heigth: 48}} />
          <Text marginLeft="16px" textAlign={'left'}>
            Your call with a <strong>{advisor}</strong> is scheduled for{' '}
            {callDetails.day}, {callDetails.month} {callDetails.date},{' '}
            {callDetails.startTime} - {callDetails.endTime}.
            {rescheduleEnabled && (
              <>
                {' '}
                <Link
                  css={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={onReschedule}>
                  Reschedule the call
                </Link>
              </>
            )}
          </Text>
        </Flex>
      </Alert>
    </Box>
  </Flex>
);

export default ScheduledCallAlert;

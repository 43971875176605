import styled from '@emotion/styled/macro';

import {darkGray, gray} from '@renofi/utils/src/colors';

export const List = styled.ul(() => ({
  position: 'relative',
  marginBottom: 24,
  marginTop: 24,
  li: {
    marginLeft: 16,
    color: darkGray,
    textAlign: 'left',
    span: {
      color: gray,
    },
    '&::marker': {
      color: gray,
    },
  },
}));

import styled from '@emotion/styled/macro';
import facepaint from 'facepaint';
import {Image, Flex, Link} from 'rebass';
import {NavLink} from 'react-router-dom';

import {navbarBreakpoint} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';
import {MODAL_ZINDEX} from '@renofi/components';

const mediaQuery = facepaint(
  [navbarBreakpoint].map((bp) => `@media (min-width: ${bp}px)`),
);
const borderStyle = ['1px solid rgba(0, 96, 153, 0.15)', 'none'];

export const Logo = styled(Image)(
  mediaQuery({
    width: [80, 120],
    height: 'auto',
    paddingLeft: [0, 13],
  }),
);

export const GcLogo = styled(Image)(
  mediaQuery({
    marginLeft: [16, 0],
    marginRight: [0, 13],
    maxWidth: [100, 120],
    maxHeight: 60,
  }),
);

export const NavbarWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    padding: ['12px 0 0 0', '20px 0'],
    minWidth: ['100%', 300],
    width: ['100%', 300],
    position: ['fixed', 'sticky'],
    top: 0,
    left: 0,
    zIndex: 100,
    background: 'white',
    marginRight: ['0', '20px'],
    borderBottom: borderStyle,
    overflow: 'scroll',
  }),
  ({isOpen}) =>
    mediaQuery({
      height: [isOpen ? '100vh' : 70, '100vh'],
    }),
);

export const TopBar = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    justifyContent: ['start', 'space-between'],
    padding: '0 7px',
    width: ['100%', 'auto'],
  }),
);

export const Navigation = styled.nav(
  mediaQuery({
    marginTop: [0, 40],
    width: ['100%', 'auto'],
  }),
);

const linkStyles = mediaQuery({
  ':first-of-type': {
    borderTop: ['1px solid #EDEDED', borderStyle],
  },
  borderBottom: ['1px solid #EDEDED', borderStyle],
  cursor: 'pointer',
  borderRadius: [0, 8],
  margin: [0, '0 8px 8px 8px'],
  display: 'block',
  background: 'white',
  textDecoration: 'none',
  opacity: [0.8, 0.6],
  lineHeight: '22px',
  color: darkBlue,
  fontSize: [20, 18],
  fontWeight: [300, 300],
  width: ['100%', 'calc(100% - 16px)'],
  img: {
    filter: 'grayscale(1)',
  },
  '&.active, &:hover': {
    img: {
      filter: 'grayscale(0)',
    },
    opacity: 1,
  },
  '&.active': {
    background: '#EFF9FF',
  },
});

export const NavigationLink = styled(NavLink)(linkStyles);
export const StyledLink = styled(Link)(linkStyles);

export const NavigationItem = styled(Flex)(
  mediaQuery({
    width: '100%',
    height: 60,
    alignItems: 'center',
    padding: ['22px 10px', '11px 8px 9px 8px'],
    textDecoration: 'none',
  }),
);

export const NavigationIcon = styled(Flex)(
  mediaQuery({
    marginTop: -4,
    width: 36,
    marginRight: '4px',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const NavbarWrapperMobile = styled(Flex)(
  mediaQuery({
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    width: '100%',
    height: 70,
    background: 'white',
    boxShadow: '0px 2px 4px 0px #0000001A',
    padding: '0 13px',
  }),
);

export const BottomNavMobile = styled(Flex)(
  mediaQuery({
    position: 'fixed',
    bottom: 0,
    left: 0,
    justifyContent: 'space-between',
    width: '100%',
    height: 70,
    padding: 5,
    background: 'white',
    zIndex: MODAL_ZINDEX - 1,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1981)',
  }),
);

export const BottomNavExtension = styled(Flex)(
  mediaQuery({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100% - 70px)',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000000001,
  }),
);

export const BottomNavOption = styled(Link)(({active}) =>
  mediaQuery({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0',
    backgroundColor: active ? '#EBF5FC' : '#FFF',
    textAlign: 'center',
    lineHeight: '16px',
    color: '#212F49',
    border: '1px solid transparent',
    borderRadius: 8,
    img: {
      filter: active ? 'greyscale(0)' : 'grayscale(1)',
    },
    opacity: active ? 1 : 0.6,
  }),
);

export const NavigationLinkMobile = styled(Flex)(({active}) =>
  mediaQuery({
    ':first-of-type': {
      borderTop: ['1px solid #EDEDED', borderStyle],
    },
    borderBottom: ['1px solid #EDEDED', borderStyle],
    cursor: 'pointer',
    borderRadius: [0, 8],
    margin: [0, '0 8px 8px 8px'],
    display: 'block',
    background: active ? '#EFF9FF' : 'white',
    textDecoration: 'none',
    opacity: active ? 1 : 0.6,
    lineHeight: '22px',
    color: darkBlue,
    fontSize: [20, 18],
    fontWeight: [300, 300],
    width: ['100%', 'calc(100% - 16px)'],
    img: {
      filter: active ? 'greyscale(0)' : 'grayscale(1)',
    },
  }),
);

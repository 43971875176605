import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Panel} from '@renofi/components';

const ACTION_PADDING = 24;

export default styled(Panel)(
  mediaQuery({
    borderRadius: [0, 8],
    boxShadow: ['none', '0px 1px 2px rgba(0, 0, 0, 0.4);'],
    padding: [24, '24px 24px'],
    position: 'relative',
  }),
  ({css}) => mediaQuery(css),
);

export const ActionsWrapper = styled(Flex)(
  mediaQuery({
    position: 'absolute',
    zIndex: 100,
    right: ACTION_PADDING,
  }),
  ({actionMobileBottom}) =>
    mediaQuery({
      top: [actionMobileBottom ? 'none' : ACTION_PADDING, ACTION_PADDING],
      ...(actionMobileBottom ? {bottom: [ACTION_PADDING, 'none']} : {}),
      width: [
        actionMobileBottom ? `calc(100% - ${ACTION_PADDING * 2}px)` : 'auto',
        'auto',
      ],
    }),
);

import {useState} from 'react';

import {useLocalStorage} from '@renofi/utils';
import {chilipiper} from '@renofi/analytics';

export default function useSchedule(callType = '') {
  const [_, setScheduledDetails] = useLocalStorage(
    `renofi:${callType}scheduledAt`,
    null,
  );
  const scheduledAtString = window.localStorage.getItem(
    `renofi:${callType}scheduledAt`,
  );
  const scheduleDetails =
    scheduledAtString === 'undefined' ? '' : JSON.parse(scheduledAtString);
  const {start, end} = scheduleDetails || {};
  const today = new Date();
  const callDetails = start && end && chilipiper.parseCallDetails(start, end);
  const isScheduleEnded = today > end;
  const [isCallScheduled, setIsCallScheduled] = useState(
    Boolean(callDetails) && !isScheduleEnded,
  );
  function onScheduleSuccess({detail}) {
    setScheduledDetails(detail);
    setIsCallScheduled(true);
  }

  function appendCalendar({
    onClose,
    onSuccess,
    calendarId,
    wrapperSelector = '#chilipiper-wrapper',
  }) {
    const calendar = document.querySelector(calendarId);
    const wrapper = document.querySelector(wrapperSelector);

    document.addEventListener('CP-Calendar-Close', onClose);
    document.addEventListener('CP-Calendar-Success', onSuccess);

    wrapper.append(calendar);
  }

  function removeCalendar(calendarId) {
    const calendar = document.querySelector(calendarId);
    const body = document.body;

    if (calendar) {
      body.append(calendar);
    }
  }

  return {
    callDetails,
    isCallScheduled,
    onScheduleSuccess,
    appendCalendar,
    removeCalendar,
  };
}

import React, {useEffect} from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {redirectToLogin} from '@renofi/utils';
import {useIsOpenOpportunity} from '@renofi/api';

import {modalVar, setModal} from '../api/cache';
import LeadDetailsModal from '../LeadDetailsModal';

import OpportunityModal from './OpportunityModal';

export const MODAL_TYPES = {
  OPPORTUNITY_INVITE: 'opportunity_invite',
  OPPORTUNITY_LEAD_DETAILS: 'opportunity_lead_details',
};

const Opportunity = ({children, lead, onCompleted}) => {
  const isOpenOpportunity = useIsOpenOpportunity();
  const modal = useReactiveVar(modalVar);
  const qualifiesForInvitation =
    lead?.convertedAt && !lead.borrowerRegistrationCompletedAt;
  const showModals =
    modal === MODAL_TYPES.OPPORTUNITY_INVITE ||
    modal === MODAL_TYPES.OPPORTUNITY_LEAD_DETAILS;

  useEffect(() => {
    (async () => {
      if (!lead.id) return;
      if (!lead.email) return onCompleted();

      const {data} = await isOpenOpportunity({email: lead.email});
      if (data?.isOpenOpportunity) {
        sendEvent('Dashboard/Redirect-To-Login');
        return redirectToLogin();
      }

      onCompleted();
    })();
  }, [lead.id]);

  useEffect(() => {
    if (!lead.id) return;

    if (qualifiesForInvitation) {
      setModal(MODAL_TYPES.OPPORTUNITY_INVITE);
    }
  }, [lead.id]);

  if (qualifiesForInvitation && showModals) {
    return (
      <>
        <Box css={{filter: 'blur(10px)'}}>{children}</Box>
        <OpportunityModal
          visible={modal === MODAL_TYPES.OPPORTUNITY_INVITE}
          lead={lead}
          onEdit={() => setModal(MODAL_TYPES.OPPORTUNITY_LEAD_DETAILS)}
        />
        <LeadDetailsModal
          visible={modal === MODAL_TYPES.OPPORTUNITY_LEAD_DETAILS}
          lead={lead}
          onSubmit={() => setModal(MODAL_TYPES.OPPORTUNITY_INVITE)}
          onCancel={() => setModal(MODAL_TYPES.OPPORTUNITY_INVITE)}
          onClose={() => setModal(MODAL_TYPES.OPPORTUNITY_INVITE)}
          onBrowserAction={() => setModal(MODAL_TYPES.OPPORTUNITY_INVITE)}
        />
      </>
    );
  }

  return children;
};

Opportunity.propTypes = {
  children: PropTypes.node,
  lead: PropTypes.object,
};

export default Opportunity;

import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Image} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {leeImage} from '@renofi/components';
import {useSrLO, hasCRMOwner} from '@renofi/utils';

import {crmOwnerVar} from '../../api/cache';

import renofiThumb from './img/renofi-thumb.svg';

const AdvisorThumb = ({size = 32, ...props}) => {
  const crmOwner = useReactiveVar(crmOwnerVar);
  const srLO = useSrLO();

  const [url, setUrl] = useState(
    srLO?.avatar || crmOwner?.photoUrl || getDefaultUrl(),
  );

  function getDefaultUrl() {
    return hasCRMOwner(crmOwner) ? renofiThumb : leeImage;
  }

  return (
    <Image
      css={{
        width: size,
        height: size,
        position: 'relative',
        borderRadius: 100,
      }}
      src={url}
      {...props}
      onError={() => setUrl(getDefaultUrl())}
    />
  );
};

AdvisorThumb.propTypes = {
  size: PropTypes.number,
  css: PropTypes.object,
};

export default AdvisorThumb;

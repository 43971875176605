import styled from '@emotion/styled/macro';
import {keyframes} from '@emotion/react';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

const slide = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const CardWrapper = styled(Flex)(
  mediaQuery({
    backgroundColor: '#fff',
    flexDirection: 'column',
    border: '1px solid #D8D8D8',
    borderRadius: ['8px 8px 0 0', '8px'],
    alignItems: 'center',
    maxWidth: ['100%', 252],
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: ['17px 0 24px 0', '24px 0'],
    animation: `${slide} 1s`,
  }),
);

export const CardTitle = styled(Text)(
  mediaQuery({
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#9097a4',
    marginTop: 9,
  }),
);

export const InnerPaddedWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px',
    textAlign: 'center',
  }),
);

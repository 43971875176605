import React from 'react';

import {Flex, Text} from 'rebass';

import {
  OptionWrapper,
  OptionRow,
  OptionItemText,
  OptionHeader,
  Small,
} from '../styled';
import {CASH_DISBURSEMENT_TYPE} from '../consts';

import {ComingSoonSticker} from './styled';

const OptionSetItemWrapper = ({children, large}) => (
  <Flex width="100%" alignItems="baseline" py={large ? '8px' : 0}>
    {children}
  </Flex>
);

const ComingSoonOption = ({state, cashDisbursementType, single}) => {
  return (
    <OptionWrapper qualified single={single}>
      <ComingSoonSticker />
      <OptionHeader px={100}>
        <h1>
          <mark>RenoFi</mark> Loans
        </h1>
        <Small textAlign="center" mt="8px">
          RenoFi Loans are currently not available in {state} but we’ll let you
          know as soon as we launch!
        </Small>
      </OptionHeader>

      <OptionRow padded>
        <OptionSetItemWrapper large>
          <OptionItemText>Loan amounts:</OptionItemText>
          <OptionItemText bold small>
            $25,000 to $500,000
          </OptionItemText>
        </OptionSetItemWrapper>

        <OptionSetItemWrapper large>
          <OptionItemText>Rate:</OptionItemText>
          <OptionItemText bold small>
            Variable & Fixed rates available
          </OptionItemText>
        </OptionSetItemWrapper>

        <OptionSetItemWrapper large>
          <OptionItemText>Terms:</OptionItemText>
          <OptionItemText bold small>
            Up to 10 year draw phase, followed by up to 20 year amortization
          </OptionItemText>
        </OptionSetItemWrapper>

        <OptionSetItemWrapper large>
          <OptionItemText>Borrowing power:</OptionItemText>
          <OptionItemText bold small>
            Up to 90% of the after renovation value
          </OptionItemText>
        </OptionSetItemWrapper>

        <OptionSetItemWrapper large>
          <OptionItemText>Disbursement:</OptionItemText>
          <OptionItemText bold small>
            Full amount available at closing
          </OptionItemText>
        </OptionSetItemWrapper>

        <Flex width="100%" my={24}>
          <Text fontSize={14}>
            <strong>No need to refinance your existing mortgage</strong>
          </Text>
        </Flex>

        <OptionSetItemWrapper large>
          <OptionItemText italic>
            {CASH_DISBURSEMENT_TYPE[cashDisbursementType]}
          </OptionItemText>
        </OptionSetItemWrapper>
      </OptionRow>
    </OptionWrapper>
  );
};

export default ComingSoonOption;

import React from 'react';

import PropTypes from 'prop-types';

import arrow from '../img/arrow-red.svg';

import {Button, Image} from './styled';

const Buttons = ({next, previous}) => {
  return (
    <>
      <Button onClick={() => previous()} left>
        <Image width={13} minWidth={13} height={10} src={arrow} />
      </Button>
      <Button onClick={() => next()}>
        <Image width={13} minWidth={13} height={10} src={arrow} left />
      </Button>
    </>
  );
};

Buttons.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
};

export default Buttons;

import React from 'react';

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

import {PanelBottomText} from '../styled';

const ProductOptionsDisclaimer = ({loanProducts}) => {
  const isRenofiAdminFeeApplied = loanProducts.some((p) =>
    Boolean(p.renofiAdminFee),
  );

  return (
    <Flex
      flexWrap="wrap"
      justifyContent="center"
      textAlign="left"
      py={[40, 24]}>
      <PanelBottomText>
        <p>
          <b>* Rate & Monthly Payment:</b> Calculated using the lowest rate
          offered by lender based on current market conditions and applicants
          with excellent credit as determined by each lender’s credit criteria
          (typically, a credit score of at least 740 and debt-to-income of 50%
          or less). <b>Rates subject to market changes without notice.</b> Your
          actual rate and monthly payment may be higher and determined by the
          lender.
        </p>
        <p>
          <b>Loan Options:</b> Loan options presented are preliminary based on
          information you provided and from your credit report if you authorized
          a soft credit inquiry by RenoFi. Your actual rate, monthly payment,
          loan type and loan amount could change and are determined by the
          lender based on your credit, income and other information provided in
          your loan application with the lender, if you choose to apply. Loan
          options are representative examples of available loan options; other
          loan options may be available to you. Schedule a call with a
          Renovation Advisor to learn more.
        </p>
        <p>
          <b>Closing Costs & Other Terms:</b> Loan terms and conditions apply,
          including acceptable membership requirements stipulated by third-party
          credit union lenders. Closing costs apply and determined by lender but
          will not be incurred until you finalize your loan.
        </p>
        {isRenofiAdminFeeApplied && (
          <p>
            <b>Processing Fee:</b> RenoFi charges a $599 processing for Personal
            Loans it originates where allowed by state law. Processing fee is
            paid out of loan proceeds and is not assessed until your loan funds;
            whether a processing fee applies is not determined until you select
            a lender and loan product and continue with the loan application
            process. No processing fee is assessed or due if your personal loan
            does not close and fund.
          </p>
        )}
        <p>
          <b>About RenoFi:</b> RenoFi is a financial technology company that
          operates an online marketplace that allows homeowners to view various
          financing options available through its partner lenders for
          residential home renovation projects. RenoFi is not a lender, does not
          make credit decisions, nor does it issue loan commitments or approvals
          or rate lock-in agreements. RenoFi Design is a design service offered
          by RenoFi.
        </p>
      </PanelBottomText>
    </Flex>
  );
};

ProductOptionsDisclaimer.propTypes = {
  loanProducts: PropTypes.array,
};

export default ProductOptionsDisclaimer;

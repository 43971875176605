import React from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {Loader as LoaderComponent} from '@renofi/components';
import {lightGray} from '@renofi/utils/src/colors';

const Loader = ({width = '100%', pt = 40}) => {
  return (
    <Flex
      css={{width, paddingTop: pt}}
      alignItems="center"
      justifyContent="center">
      <LoaderComponent color={lightGray} size={2} />
    </Flex>
  );
};

Loader.propTypes = {
  width: PropTypes.number,
};

export default Loader;

import {useState} from 'react';

import {useReactiveVar} from '@apollo/client';
import {Box, Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useUpdateScenario} from '@renofi/api';
import {gray} from '@renofi/utils';
import {Modal, Textarea, Button, Switch} from '@renofi/components';

import {leadVar} from '../api/cache';

const BasicProjectDetailsModal = ({show, onClose}) => {
  const {id, email, loanAmount, propertyUseType} = useReactiveVar(leadVar);
  const [details, setDetails] = useState('');
  const [designRequested, setDesignRequested] = useState(false);

  const {updateScenario} = useUpdateScenario();

  const onSubmit = () => {
    if (details) {
      updateScenario({
        variables: {id, additionalRenovationDetails: details},
      });
    }

    if (details || designRequested) {
      sendEvent('Dashboard/Basic-Project-Details-Submitted', {
        email,
        loanAmount,
        propertyUseType,
        designRequested,
        details,
        acValue: `3d_design${designRequested ? '_' : '_not_'}requested`,
      });
    }

    onClose();
  };

  return (
    <Modal
      hideCloseButton
      onClose={onClose}
      show={show}
      width="100%"
      css={{width: '100%', maxWidth: 740}}
      contentCss={{
        padding: ['64px 20px', '48px 32px 25px 32px'],
        borderRadius: [0, 16],
      }}>
      <Text fontSize={32} lineHeight="normal" textAlign="center">
        Tell us a bit about your project and what you’re looking to do?
      </Text>

      <Text
        mt={24}
        mb={[24, 16]}
        fontSize={20}
        fontWeight="normal"
        textAlign="center">
        This will help us select the right contractor for your project.
      </Text>
      <Textarea
        id="additionalRenovationDetails"
        name="additionalRenovationDetails"
        placeholder="Enter project description here"
        height={140}
        onChange={setDetails}
      />

      <Flex
        mt={24}
        padding="12px 16px"
        css={{gap: 16, border: '1px solid #d8d8d8', borderRadius: 8}}>
        <Box>
          <Switch
            checked={designRequested}
            onClick={() => setDesignRequested(!designRequested)}
          />
        </Box>
        <Box width="100%" flexDirection="column">
          <Text>I want 3D designs for my project</Text>
          <Text fontSize={14} lineHeight="normal" color={gray}>
            Get help planning your renovation with our remote design service.
            Receive preliminary designs & cost estimates to kick start your
            renovation process!
          </Text>
        </Box>
      </Flex>

      <Flex
        mx="auto"
        mt={20}
        maxWidth={366}
        justifyContent="center"
        css={{gap: 10}}>
        <Button css={{flex: 1}} secondary onClick={onClose}>
          Skip
        </Button>
        <Button css={{flex: 1}} onClick={onSubmit}>
          Submit
        </Button>
      </Flex>
    </Modal>
  );
};

export default BasicProjectDetailsModal;

import {InMemoryCache} from '@apollo/client';

import {leadPolicy as lead} from './lead';
import {contractorPolicy as contractor} from './contractor';
import {loanProductsPolicy as loanProducts} from './loanProducts';
import {priceScenariosPolicy as priceScenarios} from './priceScenarios';
import {modalPolicy as modal} from './modal';
import {crmOwnerPolicy as crmOwner} from './crmOwner';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        lead,
        contractor,
        modal,
        loanProducts,
        priceScenarios,
        crmOwner,
      },
    },
  },
});

import React from 'react';

import {Flex, Text} from 'rebass';

import {gray, blue} from '@renofi/utils';
import {Button} from '@renofi/components';

import ZipMap from './ZipMap';

const FindContractorCTA = ({zipCode, onSubmit}) => (
  <>
    <ZipMap zipCode={zipCode} />
    <Text fontSize={24} my={20} textAlign="center">
      There are contractors available in your area!
    </Text>
    <Flex justifyContent="center">
      <Button onClick={onSubmit}>Find contractors</Button>
    </Flex>
    <Text mt={24} fontSize={14} color={gray} textAlign="center">
      By continuing, you agree that we may share your contact information (name,
      email and phone number) with up to 3 contractors in your area.
    </Text>
    <Text
      mt={[16, 24]}
      mb={[28, 32]}
      fontSize={16}
      color={blue}
      textAlign="center">
      100% free of charge for homeowners and the contractors.
    </Text>
  </>
);

export default FindContractorCTA;

import {makeVar} from '@apollo/client';

// Reactive variables
export const priceScenariosVar = makeVar([]);

// Setters
export const setPriceScenarios = (priceScenarios) => {
  priceScenariosVar(priceScenarios);
};

// Apollo field policy
export const priceScenariosPolicy = {
  read() {
    return priceScenariosVar();
  },
};

import React from 'react';

import {Box, Text} from 'rebass';

import {gray, darkBlue, fadeGreen} from '@renofi/utils';
import {CheckOutlinedIcon} from '@renofi/components';

const FindContractorCTA = () => (
  <>
    <Box mx="auto">
      <CheckOutlinedIcon
        size={61}
        fill={fadeGreen}
        outlineColor={darkBlue}
        checkColor={darkBlue}
      />
    </Box>
    <Text mt={28} fontSize={32} textAlign="center">
      Request received!
    </Text>
    <Text mt={24} mb={48} px={[0, 75]} color={gray} textAlign="center">
      You will receive up to 3 RenoFi contractor recommendations via email
      shortly. We do our best to fill requests instantly, but occasionally it
      may take us up to 2 business days to find the right contractor for your
      project.
    </Text>
  </>
);

export default FindContractorCTA;

import {useEffect, useState} from 'react';

import {Flex, Text} from 'rebass';
import * as Sentry from '@sentry/react';

import {Alert, Button, Modal, Toggle} from '@renofi/components';
import {
  getEnvironmentUrl,
  getLeadCreditScore,
  useLocalStorage,
  daysPassed,
} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {EditLeadDetails, LeadDetailsForm} from '@renofi/modules';
import {
  isAuthenticated,
  isFakeLead,
  savePersonalLeadData,
} from '@renofi/utils/src/lead';
import {ModalFooter, ModalHeader} from '@renofi/components/src/Modal';
import {enums, useUpdateScenarioPersonal} from '@renofi/api';
import {gray} from '@renofi/utils/src/colors';
import {formatDate} from '@renofi/utils/src/date';

import {setLead} from '../api/cache';

import {List} from './styled';
import ValueItem from './ValueItem/ValueItem';

const ProjectDetailsModal = ({show, lead, onClose}) => {
  const {id} = lead || {};
  const [, setStorage] = useLocalStorage('renofi:returnedAt');
  const [status, setStatus] = useState('idle');
  const {updateScenarioPersonal, loading} = useUpdateScenarioPersonal({id});

  const sixtyDaysPassed = daysPassed(60, lead.dashboardLastVisitedAt);

  useEffect(() => {
    if (show) {
      setStorage(new Date());
      sendEvent('Dashboard/Project-Details-Dialog', {
        sixtyDaysSinceLastVisit: sixtyDaysPassed,
      });
    }

    return () => {
      setLead({editProject: false});
    };
  }, [show]);

  function onEdit() {
    setStatus('edit');
  }

  async function onEditSubmit(leadDetails) {
    setLead(leadDetails);
    savePersonalLeadData(leadDetails, id);

    if (isAuthenticated(id)) {
      await updateScenarioPersonal({variables: {...leadDetails, id}});
    } else {
      Sentry.captureException(
        'Skipping updateScenarioPersonal mutation because jwt is missing. ' +
          'This most likely creates inconsistencies and should be fixed.',
        {
          extra: {id, leadDetails},
        },
      );
    }

    sendEvent('Dashboard/Personal-Details-Changed', {
      changedDetails: Object.keys(leadDetails).filter(
        (k) => lead[k] !== leadDetails[k],
      ),
    });
    setStatus('idle');
  }

  function onYes() {
    // TODO update new field in SF
    sendEvent('Dashboard/Project-Details-Update-Back-Yes');
    onClose();
  }

  function onNo() {
    window.open(`${getEnvironmentUrl('qualify', '4010')}/redirecting/${id}`);
    sendEvent('Dashboard/Project-Details-Update-Back-No');
  }

  return (
    <Modal light width={600} show={show} onClose={onClose} p={0}>
      <ModalHeader mb={0}>
        <Text fontSize={24}>
          {sixtyDaysPassed && <strong>Welcome back!</strong>}
          {!sixtyDaysPassed && <strong>Your project details</strong>}
        </Text>
      </ModalHeader>
      <Flex
        css={{textAlign: 'center'}}
        flexDirection="column"
        p={['16px 0 0 0', 20]}>
        <Alert info mb="8px">
          Your loan options are calculated based on the information you
          provided. Please review and confirm if the information below is up to
          date or if you need to update it so we can recalculate your options.
        </Alert>

        <Toggle show={status === 'idle'}>
          {Boolean(isAuthenticated(id) && !isFakeLead(lead)) && (
            <EditLeadDetails lead={lead} onEdit={onEdit} />
          )}
          <List>
            <ValueItem
              label="Renovation start"
              value={enums.get(lead.renovationIdealStart)}
            />
            <ValueItem label="Desired loan amount" value={lead.loanAmount} />
            <ValueItem label="Current home value" value={lead.homeValue} />
            <ValueItem
              label="First mortgage balance"
              value={lead.firstMortgageBalance}
            />
            <ValueItem
              label="Second mortgage balance"
              value={lead.secondMortgageBalance}
            />
            <ValueItem
              label="After renovation value"
              value={lead.afterRenovatedValue}
            />
            <ValueItem
              label="Property type"
              value={enums.get(lead.propertyStructureType)}
            />
            <ValueItem
              label="Property use"
              value={enums.get(lead.propertyUseType)}
            />
            <ValueItem label="Monthly income" value={lead.monthlyIncome} />
            <ValueItem label="Monthly debt" value={lead.monthlyDebt} />
            <ValueItem
              label="Credit score"
              value={String(getLeadCreditScore(lead))}
            />
          </List>
        </Toggle>

        <Toggle show={status === 'edit'}>
          <LeadDetailsForm
            loading={loading}
            onCancel={() => setStatus('idle')}
            onSubmit={onEditSubmit}
            lead={lead}
            pt={[0, '0!important']}
            pb={[0, '0!important']}
          />
        </Toggle>
      </Flex>

      <Toggle show={status !== 'edit'}>
        <ModalFooter css={{marginTop: 0}}>
          <Flex
            alignItems={['left', 'center']}
            width="100%"
            flexDirection={['column', 'row']}>
            <Flex flex={1} flexDirection="column" my={[16, 0]}>
              <Text fontSize={18}>Is this information up to date?</Text>
              <Text fontSize={14} color={gray}>
                Last updated: {formatDate(new Date(lead.updatedByUserAt))}
              </Text>
            </Flex>

            <Flex flex={1} justifyContent="flex-end" my={[16, 0]}>
              <Button onClick={onYes} xSmall mall secondary mr={16}>
                Yes
              </Button>
              <Button onClick={onNo} xSmall internal>
                No
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </Toggle>
    </Modal>
  );
};

export default ProjectDetailsModal;

import {useEffect} from 'react';

import {isNil, omitBy, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {useUpdateScenario} from '@renofi/api';
import {isLendingTreeLead} from '@renofi/utils/src/lead';
import {sendEvent} from '@renofi/analytics';

import {leadVar} from '../api/cache';

const LendingTree = ({children, initialParams}) => {
  const lead = useReactiveVar(leadVar);
  const {id} = lead;
  const {updateScenario} = useUpdateScenario();

  useEffect(() => {
    if (!id) return;

    if (isLendingTreeLead(lead)) {
      sendEvent('Dashboard/Lending-Tree-Lead');
    }

    const variables = {
      lendingTreeSelectedAmount: parseUrlValueToNumber('amount'),
      lendingTreeSelectedApr: parseUrlValueToNumber('apr'),
      lendingTreeSelectedTerm: parseUrlValueToNumber('term'),
      lendingTreeSelectedFormName: initialParams.get('form_name'),
      lendingTreeSelectedLoanProductId:
        parseUrlValueToNumber('loan_product_id'),
    };
    const filteredVariables = omitBy(variables, isNil);

    if (isEmpty(filteredVariables)) return;

    updateScenario({variables: {id, ...filteredVariables}});
  }, [id]);

  function parseUrlValueToNumber(name) {
    const value = initialParams.get(name);
    return value ? Number.parseFloat(value) : null;
  }

  return children;
};

LendingTree.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LendingTree;

import React from 'react';

import PropTypes from 'prop-types';

import {gray} from '@renofi/utils/src/colors';

import StyledPanel, {ActionsWrapper} from './styled';

const Panel = ({children, css, action, actionMobileBottom, id}) => {
  return (
    <StyledPanel
      id={id}
      css={{overflowX: 'hidden', zIndex: 'auto', ...css}}
      white
      contentCss={{padding: 0}}
      headerCss={{
        fontWeight: 'none',
        padding: [0, 0],
        paddingBottom: [16, 24],
        color: gray,
      }}>
      {action ? (
        <ActionsWrapper actionMobileBottom={actionMobileBottom}>
          {action}
        </ActionsWrapper>
      ) : null}
      {children}
    </StyledPanel>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  actionMobileBottom: PropTypes.bool,
  action: PropTypes.node,
};

export default Panel;

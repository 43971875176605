import {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';

import {useTableSort} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import {Table, Td, Th} from './styled';

const tableHeaders = [
  {key: 'normalizedName', value: 'Loan type'},
  {key: 'repaymentPeriod', value: 'Repayment period', width: 180},
  {key: 'estimatedApr', value: 'Estimated APR', width: 140},
  {key: 'estimatedPayment', value: 'Estimated monthly payment', width: 160},
  {key: 'lenderName', value: 'Lender'},
];

const PriceScenariosTable = ({priceScenarios}) => {
  const [products, setProducts] = useState(priceScenarios);
  const {onSort, sortKey, sortOrder} = useTableSort(products, setProducts);

  useEffect(() => {
    setProducts(priceScenarios);
  }, [priceScenarios]);

  if (priceScenarios.length <= 0) {
    return null;
  }

  return (
    <Table horizontal id="eligible-products-table" responsive>
      <thead>
        <tr>
          {tableHeaders.map(({key, value, width}) => (
            <Th
              css={{textAlign: 'center', paddingRight: 30}}
              data-testid={key}
              key={key}
              width={width}
              sortOrder={sortOrder}
              isSorted={sortKey === key}
              onClick={() => onSort(key)}>
              {value}
            </Th>
          ))}
        </tr>
      </thead>

      <tbody data-testid="eligible-products-items">
        {products?.map((product) => {
          const {
            amortizationType,
            estimatedPayment,
            estimatedApr,
            id,
            repaymentPeriod,
            normalizedName,
            lenderName,
          } = product;
          return (
            <tr style={{height: 80}} align="center" key={id}>
              <Td css={{textAlign: 'left'}} name="loanType">
                <Text fontWeight="bold">{normalizedName}</Text>
              </Td>
              <Td name="repaymentPeriod">{repaymentPeriod}</Td>
              <Td name="estimatedAPR">
                <Text fontWeight="bold">{estimatedApr.toFixed(2)}%</Text>
                <Text
                  mt="5px"
                  css={{textTransform: 'capitalize'}}
                  color={gray}
                  fontWeight="normal"
                  fontSize={14}>
                  {amortizationType}
                </Text>
              </Td>
              <Td name="estimatedMonthlyPayment">
                <Text fontWeight="bold">${estimatedPayment}</Text>
              </Td>
              <Td name="lenderName">
                <Text>{lenderName}</Text>
              </Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PriceScenariosTable.propTypes = {
  priceScenarios: PropTypes.array,
  lead: PropTypes.object,
};

export default PriceScenariosTable;

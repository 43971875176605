import {useQuery} from '@apollo/client';

import {CONTRACTOR_FIND} from '../queries/contractorFind';

export default function useContractorFind(options) {
  const {refetch} = useQuery(CONTRACTOR_FIND, {...options, skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}

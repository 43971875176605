import PropTypes from 'prop-types';

import FaqItem from './FaqItem';

const Faqs = ({faqs}) => {
  return (
    <div className="faqs">
      {faqs.map((faq, i) => (
        <FaqItem key={i} faq={faq} />
      ))}
    </div>
  );
};

Faqs.propTypes = {
  faqs: PropTypes.array.isRequired,
};

export default Faqs;

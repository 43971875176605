import {useEffect, useLayoutEffect, useRef, useState} from 'react';

import {useResizeDetector} from 'react-resize-detector';
import {Box, Text} from 'rebass';
import PropTypes from 'prop-types';
import C3Chart from 'react-c3js';

import {red} from '@renofi/utils/src/colors';
import 'c3/c3.css';
import {Toggle} from '@renofi/components';
import {toShortCurrency} from '@renofi/utils/src/format';
import {useDebounce, usePrevious, useScreenSize} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {TOTAL_YEARS} from '../HomeValues';

import {ChartLabel, LegendLeft, LegendBottom, Marker} from './styled';

const isTest = process.env.REACT_APP_ENV === 'test';

const Chart = ({onChange, baseValues, renofiValues, year}) => {
  const ref = useRef(null);
  const [drag, setDrag] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [offset, setOffset] = useState(0);
  const {isMobile} = useScreenSize();
  const debouncedValue = useDebounce(year, 1000);
  const prevDebouncedValue = usePrevious(debouncedValue);

  const {width = ref?.current?.clientWidth} = useResizeDetector({
    targetRef: ref,
    handleHeight: false,
  });
  const blockWidth = width / TOTAL_YEARS - 0.7;

  useEffect(() => sendEvent('Dashboard/ARV-Chart-Show'), []);

  useEffect(() => {
    if (!prevDebouncedValue) return;

    sendEvent('Dashboard/ARV-Chart-Interaction', {
      arvChartYear: debouncedValue,
    });
  }, [debouncedValue]);

  useEffect(() => {
    setAnimate(true);
    if (isTest) return;

    setTimeout(() => setAnimate(false), 200);
  }, [year]);

  useLayoutEffect(() => {
    const el = document.getElementById('chart-wrapper');
    setOffset(el.getBoundingClientRect().left);
  }, [width]);

  const points = document.getElementsByClassName(
    `c3-shape c3-shape-${year} c3-circle c3-circle-${year}`,
  );
  const positions = [];
  for (let el of points) {
    positions.push({
      x: el.getAttribute('cx'),
      y: el.getAttribute('cy'),
    });
  }

  function move(e) {
    if (!drag) return;
    const dragXPosition = e.touches[0].clientX - offset;
    const x = Math.round(dragXPosition / blockWidth);
    if (x >= 0 && x < 16) onChange(x);
  }

  function onChartChange({x}) {
    onChange(x);
  }

  return (
    <Box
      ml={[40, 30]}
      css={{position: 'relative', marginTop: 62, cursor: 'col-resize'}}>
      <div ref={ref} id="chart-wrapper" className="chart-wrapper">
        <Box
          onTouchStart={() => setDrag(true)}
          onTouchEnd={() => setDrag(false)}
          onTouchMove={move}
          style={{left: `${positions[0]?.x - 22}px`}}
          css={{
            ...(isMobile ? {zIndex: 98} : {}),
            position: 'absolute',
            top: -24,
          }}>
          <ChartLabel>{!year ? 'Today' : `Year ${year}`}</ChartLabel>
        </Box>

        <Marker
          className={animate ? 'animate' : ''}
          x={positions[0]?.x}
          y={positions[0]?.y}
          color="#FF5253"
          shadowColor="#FF525355"
        />
        <Marker
          className={animate ? 'animate' : ''}
          x={positions[1]?.x}
          y={positions[1]?.y}
          color="#13C427"
          shadowColor="#0CA61D55"
        />

        <LegendLeft>
          <Text>${toShortCurrency(renofiValues[15])}</Text>
          <Text>${toShortCurrency(renofiValues[0])}</Text>
          <Text>${toShortCurrency(baseValues[0])}</Text>
        </LegendLeft>

        <Toggle show={Boolean(width)}>
          <C3Chart
            key={width}
            data={{
              onmouseover: onChartChange,
              columns: [
                ['basic', ...baseValues],
                ['renofi', ...renofiValues],
              ],
              selection: {draggable: true},
              colors: {
                basic: red,
                renofi: '#13C427',
              },
              type: 'spline',
            }}
            {...{
              tooltip: {show: false},
              point: {show: false},
              legend: {show: false},
              size: {height: 128, width},
              axis: {
                x: {show: false},
                y: {show: false},
              },
            }}
          />
        </Toggle>

        <LegendBottom blockWidth={blockWidth}>
          <Text>1</Text>
          <Text>Years</Text>
          <Text>15</Text>
        </LegendBottom>
      </div>
    </Box>
  );
};

Chart.propTypes = {
  onChange: PropTypes.func.isRequired,
  baseValues: PropTypes.array.isRequired,
  renofiValues: PropTypes.array.isRequired,
};

export default Chart;

import React from 'react';

import PropTypes from 'prop-types';

import {toCurrency} from '@renofi/utils/src/format';

import {Small} from '../styled';

const MonthlyPayment = ({note = '', option, state}) => {
  const monthlyPayment =
    option.renovationFractionMinMonthlyPayment || option.minMonthlyPayment;
  const {interestOnly, repaymentPeriod} = option;
  const props = {css: {fontSize: 12}};

  function getNote() {
    if (!interestOnly && repaymentPeriod && state === 'Texas')
      return `based on a term length of ${repaymentPeriod} years`;
    if (note) return note;
    if (interestOnly) return 'interest only payments';
  }

  return (
    <>
      *${toCurrency(monthlyPayment)} / month
      <Small {...props}>{getNote()}</Small>
    </>
  );
};

MonthlyPayment.propTypes = {
  state: PropTypes.string,
  option: PropTypes.object,
  isCashout: PropTypes.bool,
  isRefinance: PropTypes.bool,
};

export default MonthlyPayment;

import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export default styled(Text)(({css, fontWeight, lineHeight, fontSize}) =>
  mediaQuery({
    color: gray,
    fontSize: fontSize || 14,
    fontWeight: fontWeight || 300,
    lineHeight: lineHeight || '18px',
    ...css,
  }),
);

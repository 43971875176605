import {isObject, size} from 'lodash';

import {
  getLeadCreditScore,
  isUncompletedLendingTreeLead,
  useRenovationUtils,
} from '@renofi/utils';
import {
  getPersonalLeadData,
  savePersonalLeadData,
} from '@renofi/utils/src/lead';

import {setCrmOwner, setLead, setLoanProducts} from '../cache';

import {mapLoanPassProduct} from './utils';

export default function (response) {
  if (size(response.result)) {
    const {
      lead,
      leadPersonalAttributes,
      loanProductMatches,
      crmOwner,
      preferredProductMatch,
      loanPassMatches,
    } = response.result;
    const {
      id,
      creditScore,
      loanAmountDesiredForRenovation,
      renovationCost,
      lat,
      lng,
      streetAddress,
      zipCode,
      state,
      city,
      county,
    } = lead;
    const {isPurchase} = useRenovationUtils(lead);
    const isProductSelected = size(preferredProductMatch);
    const isUncompletedLendingTree = isUncompletedLendingTreeLead(lead);

    const productMatches = isProductSelected
      ? [preferredProductMatch]
      : isUncompletedLendingTree
        ? mapLoanPassProduct(loanPassMatches)
        : loanProductMatches;

    let productsHelpers = {};
    const amount = isPurchase ? renovationCost : loanAmountDesiredForRenovation;

    const products = productMatches.map(({product, ...otherValues}) => ({
      ...product,
      ...otherValues,
    }));

    if (products?.length) {
      const maxLoanAmounts = productMatches.map(
        (match) => match.offeredLoanAmountExcludingRefinance,
      );
      const maxLoanAmount = Math.max(...maxLoanAmounts);

      const isLowCreditScore =
        Boolean(creditScore) &&
        products.every((option) => {
          const userScoreValue = getLeadCreditScore(lead);
          return userScoreValue <= option.minCreditScore;
        });
      const desireLoanAmountAvailable =
        amount <= maxLoanAmount * 1.2 && !isLowCreditScore;

      const loanAmountByProducts = desireLoanAmountAvailable
        ? amount
        : maxLoanAmount;

      productsHelpers = {
        isLowCreditScore,
        maxLoanAmount,
        loanAmountByProducts,
      };
    }

    const personalData = isObject(leadPersonalAttributes)
      ? leadPersonalAttributes
      : getPersonalLeadData(id);

    savePersonalLeadData(personalData, id);

    setLoanProducts(products);
    setCrmOwner(crmOwner);
    setLead({
      ...lead,
      ...productsHelpers,
      ...personalData,
      location: {
        lat,
        lng,
        streetAddress,
        zipCode,
        state,
        city,
        county,
      },
      haveMatches: productMatches?.length > 0,
      cache: response.result,
    });
  }

  return response;
}

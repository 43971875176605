import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Label,
  Toggle,
  Textarea,
  PrivacyCheckbox,
  AssistantsStaticWidget as Assistants,
} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';
import {isEmail, isPhone} from '@renofi/utils/src/validate';
import {isFakeLead} from '@renofi/utils/src/lead';

import {
  Field,
  FieldWrapper,
  Heading,
  SubHeading,
  InnerWrapper,
  PhoneNumberField,
  DisclaimerWrapper,
  ButtonsWrapper,
} from './styled';
import SecuritySeal from './SecuritySeal';

const LeadDetailsForm = ({
  loading,
  lead,
  title,
  subtitle,
  phoneOnly,
  designLead,
  onSubmit,
  onCancel,
  ...props
}) => {
  const {firstName, lastName, email, phone, designProjectDetails} = lead;
  const isLeadFake = isFakeLead(lead);

  const currentLeadDetails = isLeadFake
    ? {}
    : {
        firstName,
        lastName,
        email,
        phone,
        designProjectDetails,
      };

  const [leadDetails, setLeadDetails] = useState(currentLeadDetails);
  const [isPersonalDataAvailable] = useState(
    leadDetails.firstName &&
      leadDetails.lastName &&
      isEmail(leadDetails.email) &&
      isPhone(leadDetails.phone),
  );

  useEffect(() => {
    setLeadDetails(currentLeadDetails);
  }, [firstName, lastName, email, phone, designProjectDetails]);

  const updated = Object.keys(leadDetails).some(
    (key) => leadDetails[key] !== lead[key],
  );

  const isFormValid = phoneOnly
    ? isPhone(leadDetails.phone)
    : leadDetails.firstName &&
      leadDetails.lastName &&
      isEmail(leadDetails.email) &&
      isPhone(leadDetails.phone) &&
      (isLeadFake ? leadDetails.terms : true) &&
      (designLead ? leadDetails.designProjectDetails : true);

  return (
    <InnerWrapper padding={0} {...props}>
      <Heading>{title}</Heading>
      <SubHeading>{subtitle}</SubHeading>
      {!designLead && (
        <>
          <Assistants />
          <DisclaimerWrapper>
            You will only ever be contacted by your RenoFi advisor and we don’t
            sell your information to lenders.
          </DisclaimerWrapper>
        </>
      )}
      {!phoneOnly &&
        (!designLead || (designLead && !isPersonalDataAvailable)) && (
          <>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="firstName">
                First name
              </Label>
              <Field
                autofocus
                value={leadDetails.firstName}
                onChange={(firstName) => {
                  setLeadDetails({
                    ...leadDetails,
                    firstName,
                  });
                }}
                id="firstName"
                name="firstName"
                type="firstName"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="lastName">
                Last name
              </Label>
              <Field
                autofocus
                value={leadDetails.lastName}
                onChange={(lastName) => {
                  setLeadDetails({
                    ...leadDetails,
                    lastName,
                  });
                }}
                id="lastName"
                name="lastName"
                type="lastName"
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="email">
                Email address
              </Label>
              <Field
                autofocus
                value={leadDetails.email}
                onChange={(email) => {
                  setLeadDetails({
                    ...leadDetails,
                    email,
                  });
                }}
                id="email"
                name="email"
                type="email"
              />
            </FieldWrapper>
          </>
        )}
      {(!designLead || (designLead && !isPersonalDataAvailable)) && (
        <FieldWrapper half left={!phoneOnly}>
          <Label fontSize={14} color={gray} htmlFor="phone">
            Phone number
          </Label>
          <PhoneNumberField
            autofocus
            icon
            value={leadDetails.phone}
            onChange={(phone) => {
              setLeadDetails({
                ...leadDetails,
                phone,
              });
            }}
            id="phone"
            name="phone"
            type="tel"
          />
        </FieldWrapper>
      )}
      {designLead && (
        <FieldWrapper left={!phoneOnly}>
          <Label fontSize={14} color={gray} htmlFor="phone">
            Project details
          </Label>
          <Textarea
            id="designProjectDetails"
            name="designProjectDetails"
            value={leadDetails.designProjectDetails}
            onChange={(designProjectDetails) => {
              setLeadDetails({
                ...leadDetails,
                designProjectDetails,
              });
            }}
            css={{
              resize: 'none',
            }}
          />
        </FieldWrapper>
      )}
      {isLeadFake && (
        <FieldWrapper>
          <PrivacyCheckbox
            color={gray}
            onChange={() =>
              setLeadDetails({
                ...leadDetails,
                terms: !leadDetails.terms,
              })
            }
            value={Boolean(leadDetails.terms)}
          />
        </FieldWrapper>
      )}
      <ButtonsWrapper>
        <Toggle show={Boolean(onCancel)}>
          <Button
            secondary
            width={['100%', '140px']}
            onClick={onCancel}
            mb={16}
            mr={[0, 40]}>
            Cancel
          </Button>
        </Toggle>

        <Button
          loading={loading}
          width={['100%', 'auto']}
          disabled={!(updated && isFormValid)}
          onClick={() => onSubmit(leadDetails)}>
          Continue
        </Button>
      </ButtonsWrapper>
      <SecuritySeal />
    </InnerWrapper>
  );
};

LeadDetailsForm.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  lead: PropTypes.object,
  phoneOnly: PropTypes.bool,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default LeadDetailsForm;

import {useEffect, useState} from 'react';

import {Box, Flex} from 'rebass';

import {
  CurrencyField,
  SelectField,
  Label,
  Button,
  Alert,
} from '@renofi/components';
import {useUpdateScenario, creditScore} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import {leadVar} from '../../api/cache';
import {Panel} from '../../components';

import warningImage from './images/warning.svg';
import Tooltip from './Tooltip';
import {
  PanelHeading,
  FormWrapper,
  InputWrapper,
  WarningImage,
  CTAPanel,
  Note,
} from './styled';

const ContractorIntroLeadForm = () => {
  const lead = leadVar();
  const {updateScenario} = useUpdateScenario();
  const [formData, setFormData] = useState({
    loanAmountDesiredForRenovation: lead.renovationCost,
    afterRenovatedValue: lead.homeValue
      ? lead.homeValue + 0.98 * lead.renovationCost
      : null,
  });
  const [submitting, setSubmitting] = useState(false);

  const isFormValid = Boolean(
    formData.loanAmountDesiredForRenovation &&
      formData.afterRenovatedValue &&
      formData.annualIncomePretax &&
      formData.selfReportedCreditScore,
  );

  function forEachCreditScoreOptions(callback) {
    return Object.entries(creditScore).map(([key, value]) =>
      callback(key, value),
    );
  }

  useEffect(() => {
    sendEvent('Dashboard/Contractor-Intro-Lead-Form-Shown');
  }, []);

  const onSubmit = async () => {
    setSubmitting(true);
    await updateScenario({variables: {id: lead.id, ...formData}});
    setSubmitting(false);
    sendEvent('Dashboard/Contractor-Intro-Lead-Form-Submitted', formData);
    window.location.reload();
  };

  return (
    <Box marginTop={32}>
      <PanelHeading>
        <WarningImage src={warningImage} />
        Your loan options are preliminary.
      </PanelHeading>
      <Panel
        css={{
          boxShadow: 'none!important',
          marginTop: 0,
          borderRadius: '0!important',
        }}>
        <Alert>
          To update your loan options, please complete the missing information
          below.
        </Alert>
        <FormWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="loanAmountDesiredForRenovation" small>
                Loan amount
              </Label>
              <Tooltip title="Loan amount">
                The amount you are looking to borrow for your home renovation.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="loanAmountDesiredForRenovation"
              icon
              value={formData.loanAmountDesiredForRenovation}
              onChange={(loanAmountDesiredForRenovation) =>
                setFormData({...formData, loanAmountDesiredForRenovation})
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="afterRenovatedValue" small>
                Expected after renovation value
              </Label>
              <Tooltip title="Expected after renovation value">
                This is the estimated value of the property after the project
                has been completed.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="afterRenovatedValue"
              icon
              value={formData.afterRenovatedValue}
              onChange={(afterRenovatedValue) =>
                setFormData({...formData, afterRenovatedValue})
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="firstMortgageBalance" small>
                Estimated 1st mortgage balance
              </Label>
              <Tooltip title="First mortgage balance">
                The total outstanding mortgage balance on the house you are
                planning to renovate contributes to the maximum loan amount that
                you can borrow. Check your mortgage statement or online bank
                account to find the most recent outstanding balance.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="firstMortgageBalance"
              icon
              value={formData.firstMortgageBalance}
              onChange={(firstMortgageBalance) =>
                setFormData({...formData, firstMortgageBalance})
              }
            />
            <Note>Leave empty if no 1st mortgage</Note>
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="secondMortgageBalance" small>
                Estimated 2nd mortgage balance
              </Label>
            </Flex>
            <CurrencyField
              id="secondMortgageBalance"
              icon
              value={formData.secondMortgageBalance}
              onChange={(secondMortgageBalance) =>
                setFormData({...formData, secondMortgageBalance})
              }
            />
            <Note>Leave empty if no 2nd mortgage</Note>
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="annualIncomePretax" small>
                Annual gross household income before tax
              </Label>
              <Tooltip title="Annual gross household income before tax">
                This is the combined gross cash income of all applicants who are
                members of the household. For example, if Mary and Joe married
                and Mary earns $50,000 while Joe earns $45,000, their combined
                pre-tax household income is $95,000.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="annualIncomePretax"
              icon
              value={formData.annualIncomePretax}
              onChange={(annualIncomePretax) =>
                setFormData({...formData, annualIncomePretax})
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="monthlyDebt" small>
                Your total monthly debt
              </Label>
              <Tooltip title="Monthly debt">
                The combined amount of all your monthly debt payments, including
                loans, credit card bills, mortgage payments, and other regular
                financial obligations.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="monthlyDebt"
              icon
              value={formData.monthlyDebt}
              onChange={(monthlyDebt) =>
                setFormData({...formData, monthlyDebt})
              }
            />
            <Note>Leave empty if no monthly debt</Note>
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="creditScore" small>
                Credit Score
              </Label>
            </Flex>
            <SelectField
              autoComplete="false"
              value={formData.selfReportedCreditScore}
              onChange={(selfReportedCreditScore) =>
                setFormData({...formData, selfReportedCreditScore})
              }
              id="creditScore"
              name="creditScore">
              <option key="" value=""></option>
              {forEachCreditScoreOptions((key, value) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </SelectField>
          </InputWrapper>
        </FormWrapper>
      </Panel>
      <CTAPanel>
        <Button
          disabled={!isFormValid || submitting}
          loading={submitting}
          onClick={onSubmit}>
          Update my loan options
        </Button>
      </CTAPanel>
    </Box>
  );
};

export default ContractorIntroLeadForm;

import {isNil} from 'lodash';

const calculateRepaymentPeriod = (loanTermMonths) => {
  const repaymentPeriod = Math.floor(loanTermMonths / 12);

  if (repaymentPeriod > 0) {
    return `${repaymentPeriod} years`;
  }

  return `${repaymentPeriod} months`;
};

function normalizeProductType(name) {
  const lower = name.toLowerCase();

  if (lower.includes('personal')) {
    return 'Personal Loan';
  } else if (lower.includes('heloc')) {
    return 'HELOC';
  } else if (lower.includes('heloan')) {
    return 'Home Equity Loan';
  }

  return name;
}

function normalizeProductName(name) {
  const lower = name.toLowerCase();

  if (lower.includes('renofi')) {
    return 'RenoFi ' + normalizeProductType(name);
  }

  return normalizeProductType(name);
}

function filterLowestEstimatedApr(priceScenario, dictionary = {}) {
  const filteredScenario = priceScenario.filter(
    ({normalizedName, repaymentPeriod, estimatedApr}) => {
      const key = `${normalizedName}_${repaymentPeriod}`;

      return dictionary[key] === estimatedApr;
    },
  );

  return filteredScenario;
}

export function mapPriceScenario(priceScenario) {
  const dictionary = {};

  const mappedScenario = priceScenario.map((loanProduct) => {
    const {name, loanTermMonths, estimatedApr} = loanProduct;

    const normalizedName = normalizeProductName(name);

    const repaymentPeriod = calculateRepaymentPeriod(loanTermMonths);

    // save dictionary for filtering in the next step
    const key = `${normalizedName}_${repaymentPeriod}`;
    if (isNil(dictionary[key]) || estimatedApr < dictionary[key]) {
      dictionary[key] = estimatedApr;
    }

    return {
      ...loanProduct,
      normalizedName,
      repaymentPeriod,
    };
  });

  const filtered = filterLowestEstimatedApr(mappedScenario, dictionary);

  return filtered;
}

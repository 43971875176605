import {Flex, Text, Image} from 'rebass';

import {gray, darkBlue, blueGray} from '@renofi/utils';

import vetted from './img/vetted.svg';
import licensed from './img/licensed.svg';
import insured from './img/insured.svg';
import {ContractorStatusCard} from './styled';

const STATUSES = [
  {
    label: 'Vetted',
    color: '#FFF7E9',
    icon: vetted,
    message:
      'Completed our vetting process of conducting due diligence and verifying previous projects.',
  },
  {
    label: 'Licensed',
    color: '#EFEFFF',
    icon: licensed,
    message:
      'Provided the necessary documents to operate in your state of residence.',
  },
  {
    label: 'Insured',
    color: '#E5F5FF',
    icon: insured,
    message:
      'Provided the necessary documents for insurance for the type of work they conduct.',
  },
];

const ContractorStatus = () => (
  <Flex
    paddingX={[20, 32]}
    pt={24}
    flexDirection="column"
    css={{borderTop: `1px solid ${blueGray}`}}>
    <Text fontSize={24} color={gray} textAlign={['center', 'left']}>
      Contractors we recommend to you are always
    </Text>
    <Flex mt={24} flexDirection={['column', 'row']} css={{gap: 24}}>
      {STATUSES.map(({label, color, icon, message}) => (
        <ContractorStatusCard key={label} backgroundColor={color}>
          <Image height={80} src={icon} />
          <Text mt={16} fontSize={20} fontWeight={700}>
            {label}
          </Text>
          <Text
            mt="8px"
            fontSize={14}
            lineHeight="21px"
            color={gray}
            textAlign="center">
            {message}
          </Text>
        </ContractorStatusCard>
      ))}
    </Flex>
    <Text
      mt={27}
      fontSize={14}
      fontStyle="italic"
      lineHeight="18px"
      color={darkBlue}>
      Disclaimer: Recommendations are suggestions only and should not replace
      your own due diligence on the contractor; RenoFi is not responsible for
      the workmanship of the contractor.
    </Text>
  </Flex>
);
export default ContractorStatus;

import React from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {ChiliPiperPopup, POPUP_TYPES} from '@renofi/modules';

import {leadVar} from '../api/cache';

const ScheduleModal = ({
  visible,
  onSuccess,
  calendarId,
  title,
  subtitle,
  onClose,
  onEdit,
  editEnabled = true,
}) => {
  const lead = useReactiveVar(leadVar);

  function onCloseWithEvent() {
    sendEvent('Dashboard/Close-Calendar-Click');
    onClose && onClose();
  }

  const onEditWithEvent = () => {
    sendEvent('Dashboard/Schedule-Call-Calendar-Change-Info-Clicked');
    onEdit && onEdit();
  };

  if (!visible) return null;

  return (
    <ChiliPiperPopup
      popupType={POPUP_TYPES.SCHEDULED_CALL}
      cpWrapper="loan_options_cta_schedule_call_ready"
      calendarId={calendarId}
      visible={visible}
      title={title}
      subtitle={subtitle}
      editEnabled={editEnabled}
      lead={lead}
      onEdit={onEditWithEvent}
      onClose={onCloseWithEvent}
      onSuccess={onSuccess}
    />
  );
};

ScheduleModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  callType: PropTypes.string,
  editEnabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  calendarId: PropTypes.string,
};

export default ScheduleModal;

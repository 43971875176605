import {sendEvent} from '@renofi/analytics';
import {deleteAllCookies} from '@renofi/utils/src/cookies';
import {getEnvironmentUrl, getMainSiteUrl} from '@renofi/utils';

export function logout() {
  deleteAllCookies({exceptions: ['renofi_cookie_consent']});
  sendEvent('Dashboard/Logout');
  window.location.assign(getMainSiteUrl());
}

export function redirectToLogin() {
  const loginAppUrl = getEnvironmentUrl('login', '4004');
  sendEvent('Dashboard/Login-Options-Clicked');
  window.location.assign(loginAppUrl);
}

import styled from '@emotion/styled/macro';
import {Flex, Link, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkGray, lightGray} from '@renofi/utils/src/colors';

export const FormRow = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    width: ['100%', 'auto'],
    alignItems: 'flex-end',
  }),
);

export const InputWrapper = styled(Box)(
  mediaQuery({
    marginRight: [0, 16],
    flex: 3,
    width: ['100%', 'auto'],
  }),
);

export const StyledLink = styled(Link)(
  mediaQuery({
    cursor: 'pointer',
    fontSize: 16,
    color: darkGray,
    borderBottom: `solid 1px ${lightGray}`,
    textDecoration: 'none',
  }),
);

export const Grid = styled(Box)(({columns}) =>
  mediaQuery({
    width: '100%',
    display: 'grid',
    gridGap: '24px 8px',
    gridTemplateColumns: ['1fr 1fr', columns || '1fr 1fr 1fr 1fr'],
  }),
);

export const List = styled.ul(
  mediaQuery({
    textAlign: 'left',
    paddingLeft: 24,
    margin: '6px 0',
    fontSize: 16,
    li: {
      lineHeight: '150%',
    },
  }),
);

import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Flex)(
  mediaQuery({
    width: '100%',
    minHeight: '100%',
    backgroundImage: 'url(/img/footer.svg)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '100% 100%',
    paddingTop: [70, 0],
  }),
  ({css}) => mediaQuery(css),
);

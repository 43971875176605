import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const DesignTitle = styled(Text)(
  mediaQuery({
    backgroundImage: 'linear-gradient(90deg, #212F49 18.9%, #FF5253 81.79%)',
    color: 'transparent',
    backgroundClip: 'text',
    textAlign: 'center',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  }),
);

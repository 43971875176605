import styled from '@emotion/styled/macro';
import {Flex, Text, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const CardWrapper = styled(Flex)(() =>
  mediaQuery({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    width: 280,
    height: 155,
    border: 'solid 1px #DDDDDD',
    borderRadius: 5,
    boxShadow: '1px 1px 7px 0px #00000014',
    position: 'relative',
    marginTop: 30,
    transform: 'rotate(4deg)',
    backgroundSize: 30,
    overflow: 'visible',
  }),
);
export const LogoWrapper = styled(Box)(() =>
  mediaQuery({
    position: 'absolute',
    left: 16,
    top: 16,
  }),
);

export const AdvisorSubtitle = styled(Text)(() =>
  mediaQuery({
    textAlign: 'center',
    lineHeight: '14px',
    color: '#888888',
    fontSize: 13,
    marginTop: '2px',
    fontStyle: 'italic',
  }),
);

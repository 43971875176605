import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {
  Table as StyledTable,
  Td as StyledTd,
  Th as StyledTh,
  Panel as PanelComponent,
} from '@renofi/components';
import {darkBlue, gray} from '@renofi/utils/src/colors';

export const Grid = styled(Box)(({columns}) =>
  mediaQuery({
    width: '100%',
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: ['100%', columns || '1fr 1fr 1fr 1fr'],
  }),
);

export const Table = styled(StyledTable)(
  mediaQuery({
    fontSize: 14,
    color: gray,
    minWidth: 1000,
    background: 'white',
    borderRadius: 16,
    marginTop: -1,
  }),
);

export const Td = styled(StyledTd)(
  mediaQuery({
    fontWeight: 300,
    padding: '0 24px',
  }),
);

export const Th = styled(StyledTh)(
  mediaQuery({
    fontWeight: 300,
    backgroundColor: `${darkBlue} !important`,
    ':first-of-type': {
      borderTopLeftRadius: 16,
    },
    ':last-of-type': {
      borderTopRightRadius: 16,
    },
    color: 'white',
  }),
);

export const Panel = styled(PanelComponent)(
  mediaQuery({
    boxShadow: 'none !important',
    backgroundColor: 'white',
  }),
);

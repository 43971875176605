import {useEffect, useState} from 'react';

import {Text, Flex} from 'rebass';

import {Button, Modal} from '@renofi/components';
import {useLocalStorage} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {getSecondsDiff, humanTimeFormat} from '@renofi/utils/src/time';
import {EditLeadDetails} from '@renofi/modules';
import {isAuthenticated} from '@renofi/utils/src/lead';
import useReinviteBorrowerToProjectByScenario from '@renofi/api/src/hooks/useReinviteBorrowerToProjectByScenario';

import {Small} from '../components';

const FIVE_MINUTES = 60 * 2;
let timer;

const OpportunityModal = ({visible, lead, onEdit}) => {
  const [invitationSentAt, setStorage] = useLocalStorage(
    'renofi_invitation_sent_at',
  );
  const [timeLeft, setTimeLeft] = useState(null);
  const {reinviteBorrowerToProjectByScenario} =
    useReinviteBorrowerToProjectByScenario();

  useEffect(() => {
    startTimer();
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (visible) sendEvent('Dashboard/Secure-Invitation-Modal');
  }, [visible]);

  function onClick() {
    setStorage(new Date());
    startTimer({reset: true});
    reinviteBorrowerToProjectByScenario({
      variables: {scenarioId: lead.id},
    });
  }

  function startTimer({reset} = {}) {
    const diff = reset
      ? 0
      : getSecondsDiff(new Date(invitationSentAt), new Date());
    let count = FIVE_MINUTES - diff;

    if (count <= 0 && !reset) return;
    if (reset) clearInterval(timer);

    setTimeLeft(count);

    timer = setInterval(() => {
      count--;
      setTimeLeft(count);
      if (count === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  return (
    <Modal width={445} hideCloseButton light show={visible} onClose={() => {}}>
      <Flex css={{textAlign: 'center'}} flexDirection="column" p={20}>
        <Text fontWeight={100} fontSize={24} css={{lineHeight: '36px'}}>
          Check your email!
        </Text>
        <Text mt={24} fontWeight={100}>
          We recently sent you an invitation to join the RenoFi renovation loan
          portal.
        </Text>

        {isAuthenticated(lead.id) && (
          <Flex justifyContent="center">
            <EditLeadDetails lead={lead} onEdit={onEdit} />
          </Flex>
        )}

        <Button mt={24} disabled={Boolean(timeLeft)} onClick={onClick}>
          {timeLeft
            ? `Resend Again in ${humanTimeFormat(timeLeft)}`
            : 'Resend invitation'}
        </Button>

        <Small mt={24} fontSize={12}>
          If you have issues joining the portal, reach out to your Loan Officer
          or send an email to{' '}
          <a style={{fontSize: '12px'}} href="mailto:sayhi@renofi.com">
            sayhi@renofi.com
          </a>
        </Small>
      </Flex>
    </Modal>
  );
};

export default OpportunityModal;

export const CASH_DISBURSEMENT_TYPE = {
  full_upfront: 'Full loan amount available at closing',
  draws_inspection: 'Draws and inspections',
};

export const ACCORDION_TYPE = {
  AMOUNT: 'Borrow up to',
  TERMS: 'Terms up to',
  RATE: 'Rate type',
  PREPAYMENT: 'Prepayment Penalties',
  REFINANCING: 'Refinancing Required',
  DISBURSEMENT: 'Cash disbursement',
  LENDER: 'Lender Name',
};

import {useEffect, useLayoutEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {LeadDetailsForm} from '@renofi/modules';
import {sendEvent} from '@renofi/analytics';
import {Loader, Toggle} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';
import {Feedback, ThankYou} from '@renofi/modules';
import {useCookies, constants} from '@renofi/utils';

import {Panel, Page, Title} from '../components';
import {leadVar, setLead} from '../api/cache';
import AdvisorName from '../components/AdvisorName';
import AdvisorThumb from '../components/AdvisorThumb';
import useSchedule from '../hooks/useSchedule';

const {FEEDBACK_PROVIDED_COOKIE_KEY, SCHEDULE_CALENDAR_FEEDBACK_OPTIONS} =
  constants;

const SchedulePage = ({loading}) => {
  const lead = useReactiveVar(leadVar);
  const {cookie, updateCookie} = useCookies(FEEDBACK_PROVIDED_COOKIE_KEY, null);
  const [scheduleClosed, setScheduleClosed] = useState(false);
  const [feedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const isAllDetails = Boolean(
    lead.email && lead.firstName && lead.lastName && lead.phone,
  );
  const {appendCalendar, removeCalendar} = useSchedule();

  useEffect(() => sendEvent('Dashboard/Schedule-Page'), []);

  useEffect(() => {
    if (!loading && !scheduleClosed && isAllDetails) {
      appendCalendar({onClose, calendarId: '#calendar'});
    }
  }, [scheduleClosed, isAllDetails, loading]);

  useLayoutEffect(() => removeCalendar, []);

  function onClose() {
    if (cookie) return;
    setScheduleClosed(true);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Page showFooterLinks>
      <Title>Meet your advisor 👋</Title>
      <Panel>
        <Toggle show={isAllDetails}>
          {!scheduleClosed && (
            <>
              <Text
                data-testid="advisor"
                mb={16}
                mt={-10}
                fontSize={26}
                fonrWeight={800}
                lineHeight="31px">
                <AdvisorThumb mb="-6px" mr={12} size={32} />
                I’m <AdvisorName as="span" size={26} />, your dedicated advisor!
              </Text>
              <Text mb={36} color={gray}>
                {lead.firstName}, I'm very much looking forward to speaking with
                you! Please select a time from the calendar below and I'll give
                you a call:
              </Text>
            </>
          )}

          {scheduleClosed && !feedbackSubmitted && (
            <Feedback
              optionsList={SCHEDULE_CALENDAR_FEEDBACK_OPTIONS}
              onFeedbackSubmit={() => {
                updateCookie({value: true, numberOfDays: 1});
                setIsFeedbackSubmitted(true);
              }}
            />
          )}
          {scheduleClosed && feedbackSubmitted && (
            <ThankYou
              onDoneClick={() => {
                setScheduleClosed(false);
                setIsFeedbackSubmitted(false);
              }}
            />
          )}
        </Toggle>

        <Toggle show={!isAllDetails}>
          <LeadDetailsForm
            lead={lead}
            title="Please add your details"
            onSubmit={(details) => {
              setLead(details);
            }}
          />
        </Toggle>

        <div
          id="chilipiper-wrapper"
          style={{
            maxWidth: 560,
            margin: '0 auto',
            height: 1000,
            position: 'relative',
          }}
        />
      </Panel>
    </Page>
  );
};

SchedulePage.propTypes = {
  loading: PropTypes.bool,
};

export default SchedulePage;

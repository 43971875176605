import React from 'react';

import PropTypes from 'prop-types';
import {isNil, isString} from 'lodash';

import {toCurrency} from '@renofi/utils/src/format';

const ValueItem = ({label, value}) => {
  function getValue() {
    if (isString(value)) return value;
    return `$${toCurrency(value)}`;
  }
  return (
    <li>
      {label}: <span>{isNil(value) ? 'N/A' : getValue()}</span>
    </li>
  );
};

ValueItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ValueItem;

import {useEffect, useLayoutEffect} from 'react';

import {Box, Flex} from 'rebass';

import useSchedule from '@renofi/dashboard/src/hooks/useSchedule';

import {EditLeadDetails} from '../EditLeadDetails';
import {ReactComponent as CloseIcon} from '../img/close-icon.svg';
import {CPPopup, PaddedWrapper} from '../styled';

export const POPUP_TYPES = {
  HOT_CALL: 'HotCall',
  SCHEDULED_CALL: 'ScheduledCall',
};

const PopupTitles = ({title, subtitle}) => (
  <>
    {title && (
      <span className="chilipiper-title">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="subtitle">{subtitle}</span>
          </>
        )}
      </span>
    )}
  </>
);

const ChiliPiperPopup = ({
  lead,
  editEnabled,
  onEdit,
  cpWrapper = 'custom-chilipiper-wrapper',
  calendarId = '#calendar',
  visible,
  popupType,
  title = 'No problem, when would be a better time to have a quick call?',
  subtitle = 'Continue On! Rates and terms are not locked and could change!',
  onClose,
  onSuccess,
}) => {
  const {appendCalendar, removeCalendar} = useSchedule();

  useEffect(() => {
    if (visible) {
      appendCalendar({
        wrapperSelector: `#${cpWrapper}`,
        calendarId,
        onClose,
        onSuccess,
      });
    }
  }, [visible]);

  useLayoutEffect(() => () => removeCalendar(calendarId), []);

  return (
    <CPPopup>
      <div id="cp-container">
        <PaddedWrapper>
          <Box
            data-testid="close-button"
            css={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
            <CloseIcon onClick={onClose} />
          </Box>

          {popupType === POPUP_TYPES.SCHEDULED_CALL && (
            <PopupTitles title={title} subtitle={subtitle} />
          )}

          {editEnabled && (
            <Flex justifyContent="center">
              <EditLeadDetails lead={lead} onEdit={onEdit} />
            </Flex>
          )}

          <Box id={cpWrapper}></Box>
        </PaddedWrapper>
      </div>
    </CPPopup>
  );
};

export default ChiliPiperPopup;

import PropTypes from 'prop-types';

import {Modal} from '@renofi/components';
import {useOnBrowserAction} from '@renofi/utils';

import LeadDetailsIncompleteForm from './LeadDetailsIncompleteForm';

const LeadDetailsIncompleteModal = ({
  loading,
  lead,
  visible,
  onSubmit,
  onBrowserAction = () => {},
}) => {
  useOnBrowserAction(onBrowserAction);

  return (
    <Modal width={910} show={visible} light hideCloseButton>
      <LeadDetailsIncompleteForm
        loading={loading}
        lead={lead}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

LeadDetailsIncompleteModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  phoneOnly: PropTypes.bool,
  designLead: PropTypes.bool,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onBrowserAction: PropTypes.func,
};

export default LeadDetailsIncompleteModal;

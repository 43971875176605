import PropTypes from 'prop-types';

import {Modal} from '@renofi/components';
import {mediaQuery, useOnBrowserAction} from '@renofi/utils';

import LeadDetailsForm from './LeadDetailsForm';

const LeadDetailsModal = ({
  loading,
  lead,
  visible,
  title = 'Your Details',
  subtitle,
  phoneOnly = false,
  designLead,
  onSubmit,
  onClose,
  onCancel,
  onBrowserAction = () => {},
}) => {
  useOnBrowserAction(onBrowserAction);

  return (
    <Modal
      width={561}
      contentCss={mediaQuery({padding: 16})}
      show={visible}
      light
      onClose={onClose}>
      <LeadDetailsForm
        loading={loading}
        title={title}
        subtitle={subtitle}
        lead={lead}
        onSubmit={onSubmit}
        onCancel={onCancel}
        phoneOnly={phoneOnly}
        designLead={designLead}
      />
    </Modal>
  );
};

LeadDetailsModal.propTypes = {
  lead: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  phoneOnly: PropTypes.bool,
  designLead: PropTypes.bool,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onBrowserAction: PropTypes.func,
};

export default LeadDetailsModal;

import {Flex, Image} from 'rebass';

import map from './img/map.svg';
import {MapZipCode} from './styled';

const ZipMap = ({zipCode}) => (
  <Flex mx="auto" width={273} height={149} css={{position: 'relative'}}>
    <Image size="100%" src={map} />
    <MapZipCode>{zipCode}</MapZipCode>
  </Flex>
);

export default ZipMap;

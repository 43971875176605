import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {FatLink, OptionsWrapper} from '../styled';
import Option from '../Option';

const SecondaryOptions = ({
  show,
  lead,
  options,
  primaryOptions = [],
  secondaryOptions = [],
  isQualified,
  onClick,
}) => {
  if (!secondaryOptions.length) return null;

  return (
    <Box mt={['4px', 18]}>
      {show ? (
        <OptionsWrapper data-testid="non-single-view">
          <Box width={[1, 1 / primaryOptions.length]}>
            {secondaryOptions.map((option, index) => (
              <Option
                qualified={isQualified}
                key={index}
                lead={lead}
                options={options}
                option={option}
              />
            ))}
          </Box>
        </OptionsWrapper>
      ) : (
        <FatLink onClick={onClick}>
          <strong>Do you also need a Conventional Purchase Mortgage?</strong>
        </FatLink>
      )}
    </Box>
  );
};

SecondaryOptions.propTypes = {
  show: PropTypes.bool,
  lead: PropTypes.object,
  options: PropTypes.array,
  primaryOptions: PropTypes.array,
  secondaryOptions: PropTypes.array,
  isQualified: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SecondaryOptions;

import {makeVar} from '@apollo/client';
import {isNil} from 'lodash';

// Reactive variables
export const contractorVar = makeVar({});

// Setter
export const setContractor = (contractor) => {
  if (isNil(contractor)) {
    return contractorVar(null);
  }
  contractorVar({...contractorVar(), ...contractor});
};

// Apollo field policy
export const contractorPolicy = {
  read() {
    return contractorVar();
  },
};

import styled from '@emotion/styled/macro';
import {Flex, Box, Image, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {fadeRed, blueGray} from '@renofi/utils/src/colors';

export const PanelHeading = styled(Box)(
  mediaQuery({
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: 24,
    fontSize: '24px',
    lineHeight: 'normal',
    borderBottom: `1px solid ${blueGray}`,
    textAlign: ['center', 'left'],
  }),
);

export const WarningImage = styled(Image)(
  mediaQuery({
    margin: '0 16px -6px 0',
  }),
);

export const PanelWarning = styled(Box)(
  mediaQuery({
    backgroundColor: fadeRed,
    padding: 16,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    textAlign: ['center', 'left'],
  }),
);

export const FormWrapper = styled(Flex)(
  mediaQuery({
    paddingTop: 24,
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'flex-start',
    gap: [16, 32],
  }),
);

export const InputWrapper = styled(Box)(({css}) =>
  mediaQuery({
    flex: '0 0 auto',
    width: ['100%', 304],
    ...css,
  }),
);

export const CTAPanel = styled(Box)(
  mediaQuery({
    background: '#f7fafc',
    border: '1px solid #fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 24,
    textAlign: ['center', 'right'],
  }),
);

export const Note = styled(Text)(
  mediaQuery({
    color: '#ababab',
    fontSize: '12px',
    lineHeight: '18px',
  }),
);

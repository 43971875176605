import React from 'react';

import PropTypes from 'prop-types';

import {GCLeadOptionWrapper, OptionsWrapper} from '../styled';
import GCLeadOption from '../GCLeadOption';

const GCOptions = ({isQualified, lead, salesCard, options}) => {
  const SalesCard = salesCard;

  return (
    <>
      <GCLeadOptionWrapper
        data-testid="single-gc-view"
        width={[1, isQualified ? 1 / 2 : 1]}
        py={[0, isQualified ? 10 : 36]}>
        <GCLeadOption lead={lead} options={options} option={options[0]} />
      </GCLeadOptionWrapper>

      {isQualified && (
        <OptionsWrapper
          data-testid="single-gc-qualified-view"
          width={[1, 1 / 2]}
          height={'100%'}
          py={[40, 55]}
          px={[16, 0]}>
          {salesCard && <SalesCard isGeneralContractorView />}
        </OptionsWrapper>
      )}
    </>
  );
};

GCOptions.propTypes = {
  isQualified: PropTypes.bool,
  lead: PropTypes.object,
  salesCard: PropTypes.node,
  options: PropTypes.array,
};

export default GCOptions;

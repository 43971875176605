import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {SUBMIT_CONTRACTOR_SEARCH} from '../mutations/submitContractorSearch';

export default function useSubmitContractorSearch({onCompleted = noop} = {}) {
  const [submitContractorSearch, {data, loading, error}] = useMutation(
    SUBMIT_CONTRACTOR_SEARCH,
    {
      onCompleted: ({submitContractorSearch}) =>
        onCompleted(submitContractorSearch),
    },
  );
  const response = data?.submitContractorSearch;
  return {submitContractorSearch, response, loading, error};
}

import React from 'react';

import PropTypes from 'prop-types';
import {Link, Flex} from 'rebass';

import {Image, Text, Wrapper} from './styled';

const CarouselItem = ({imgSrc, text, linkTo, color}) => {
  return (
    <Link href={linkTo} target="_blank" css={{textDecoration: 'none'}}>
      <Wrapper bg={color}>
        <Flex height={92}>
          <Image src={imgSrc} />
        </Flex>
        <Flex alignItems="center" height={100}>
          {text && <Text>{text}</Text>}
        </Flex>
      </Wrapper>
    </Link>
  );
};

CarouselItem.propTypes = {
  linkTo: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default CarouselItem;

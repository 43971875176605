import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box, Text, Link} from 'rebass';

import {Button, Label, PrivacyCheckbox} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';
import {isEmail, isPhone} from '@renofi/utils/src/validate';

import {Field, PhoneNumberField} from './styled';
import SecuritySeal from './SecuritySeal';

const LeadDetailsForm = ({loading, lead, onSubmit}) => {
  const [leadDetails, setLeadDetails] = useState({});

  const updated = Object.keys(leadDetails).some(
    (key) => leadDetails[key] !== lead[key],
  );

  const isFormValid =
    leadDetails.firstName &&
    leadDetails.lastName &&
    isEmail(leadDetails.email) &&
    isPhone(leadDetails.phone) &&
    leadDetails.terms;

  return (
    <Box padding={['32px 0', '32px 40px']}>
      <Text fontSize={28} lineHeight="normal" textAlign={['center', 'left']}>
        Last step! Save and see your loan options, rate and monthly payment.
      </Text>
      <Text
        mt={24}
        width="100%"
        fontSize={16}
        lineHeight="normal"
        color={gray}
        textAlign={['center', 'left']}>
        Enter your details below to save your information and see your loan
        options!
      </Text>
      <Flex
        width="100%"
        mt={25}
        flexDirection={['column', 'row']}
        css={{gap: 30}}>
        <Flex flex={1} maxWidth={360} flexDirection="column" css={{gap: 24}}>
          <Box>
            <Label fontSize={14} color={gray} htmlFor="firstName">
              First name
            </Label>
            <Field
              autofocus
              value={leadDetails.firstName}
              onChange={(firstName) => {
                setLeadDetails({
                  ...leadDetails,
                  firstName,
                });
              }}
              id="firstName"
              name="firstName"
              type="firstName"
            />
          </Box>
          <Box>
            <Label fontSize={14} color={gray} htmlFor="lastName">
              Last name
            </Label>
            <Field
              autofocus
              value={leadDetails.lastName}
              onChange={(lastName) => {
                setLeadDetails({
                  ...leadDetails,
                  lastName,
                });
              }}
              id="lastName"
              name="lastName"
              type="lastName"
            />
          </Box>
          <Box>
            <Label fontSize={14} color={gray} htmlFor="email">
              Email address
            </Label>
            <Field
              autofocus
              value={leadDetails.email}
              onChange={(email) => {
                setLeadDetails({
                  ...leadDetails,
                  email,
                });
              }}
              id="email"
              name="email"
              type="email"
            />
          </Box>
          <Box>
            <Label fontSize={14} color={gray} htmlFor="phone">
              Phone number
            </Label>
            <PhoneNumberField
              autofocus
              icon
              value={leadDetails.phone}
              onChange={(phone) => {
                setLeadDetails({
                  ...leadDetails,
                  phone,
                });
              }}
              id="phone"
              name="phone"
              type="tel"
              css={{width: '70%'}}
            />
          </Box>

          <Button
            loading={loading}
            width={['100%', '70%']}
            disabled={!(updated && isFormValid)}
            onClick={() => onSubmit(leadDetails)}
            css={{marginTop: '8px'}}>
            See loan options
          </Button>
        </Flex>
        <Flex flex={1} maxWidth={400} flexDirection="column">
          <Flex
            p={20}
            backgroundColor="#F3FaFF"
            flexDirection="column"
            css={{borderRadius: 16, gap: 24}}>
            <Text fontSize={14} color={gray}>
              By entering your information and clicking See Loan Options:
            </Text>

            <PrivacyCheckbox
              color={gray}
              onChange={() =>
                setLeadDetails({
                  ...leadDetails,
                  terms: !leadDetails.terms,
                })
              }
              value={Boolean(leadDetails.terms)}>
              <Text fontSize={12} color={gray} lineHeight="18px">
                I consent to RenoFi's{' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/electronic-records-and-signatures/"
                  target="_blank">
                  Electronic Transactions/Signatures
                </Link>
                ,{' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/legal/"
                  target="_blank">
                  Terms Of Service
                </Link>
                , and{' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/privacy/"
                  target="_blank">
                  Privacy Policy
                </Link>
                .
              </Text>
            </PrivacyCheckbox>

            <PrivacyCheckbox
              color={gray}
              onChange={() =>
                setLeadDetails({
                  ...leadDetails,
                  tcpaConsent: !leadDetails.tcpaConsent,
                })
              }
              value={Boolean(leadDetails.tcpaConsent)}>
              <Text fontSize={12} color={gray} lineHeight="18px">
                I consent to receive sales and marketing calls, text messages,
                artificial/prerecorded messages, and voicemails by or on behalf
                of RenoFi using an autodialer or any {' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/sms-terms-and-conditions/"
                  target="_blank">
                  regulated equipment
                </Link>
                 to the number(s) provided, even if included on any Do-Not-Call
                list, regarding home renovation financing options. Message
                frequency varies. Msg and data rates may apply. Reply to texts
                with HELP for help or support and STOP to stop receiving texts.
                I’m not required to provide this consent as a condition of
                purchasing any products or services. I’m authorized to provide
                this consent. I have read, understand, and agree to RenoFi’s {' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/sms-terms-and-conditions/"
                  target="_blank">
                  SMS Terms and Conditions
                </Link>
                 and {' '}
                <Link
                  fontSize={12}
                  href="https://www.renofi.com/notices/privacy/"
                  target="_blank">
                  Privacy Policy
                </Link>
                .
              </Text>
            </PrivacyCheckbox>

            <Text fontSize={14} fontStyle="italic" color={gray}>
              You will only ever be contacted by your RenoFi Advisor and{' '}
              <strong>we never sell your information to lenders</strong>.
            </Text>
          </Flex>
          <SecuritySeal css={{marginTop: 28}} />
        </Flex>
      </Flex>
    </Box>
  );
};

LeadDetailsForm.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  lead: PropTypes.object,
  phoneOnly: PropTypes.bool,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default LeadDetailsForm;

import {Text} from 'rebass';

import {Button} from '@renofi/components';

import {ButtonWrapper, ThankYouContainer} from './styled';

const ThankYou = ({onDoneClick}) => {
  return (
    <ThankYouContainer>
      <Text
        css={{textAlign: 'center', fontSize: 24, padding: '100px 0 40px 0'}}>
        Thank you for your feedback
      </Text>
      <ButtonWrapper>
        <Button
          data-testid="feedback-end-button"
          css={{marginBottom: '100px', width: '200px'}}
          onClick={onDoneClick}>
          Close
        </Button>
      </ButtonWrapper>
    </ThankYouContainer>
  );
};

export default ThankYou;

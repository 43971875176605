import {makeVar} from '@apollo/client';

// Reactive variables
export const leadVar = makeVar({});

// Setter
export const setLead = (lead) => {
  leadVar({...leadVar(), ...lead});
};

export const resetLead = () => {
  leadVar({});
};

// Apollo field policy
export const leadPolicy = {
  read() {
    return leadVar();
  },
};

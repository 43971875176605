import {makeVar} from '@apollo/client';

// Reactive variables
export const modalVar = makeVar({});

// Setter
export const setModal = (modal) => {
  modalVar(modal);
};

// Apollo field policy
export const modalPolicy = {
  read() {
    return modalVar();
  },
};

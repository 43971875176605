import React, {useEffect, useState} from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {useUpdateScenario} from '@renofi/api';
import {webToCase, useCookies} from '@renofi/utils';
import {Faqs} from '@renofi/components';
import {
  CONTRACTOR_CASE_CREATED_KEY,
  getKeyWithUUID,
} from '@renofi/utils/src/lead';

import {leadVar, setLead} from '../api/cache';
import {Page, Title} from '../components';

import FindContractorCTA from './FindContractorCTA';
import FindContractorRequested from './FindContractorRequested';
import ContractorStatus from './ContractorStatus';
import BasicProjectDetailsModal from './BasicProjectDetailsModal';
import faqs from './faqs';
import {Panel} from './styled';

const DEPRECIATED_COOKIE_KEY = 'dashboard:contractor_case_created';

const FindContractor = () => {
  const lead = useReactiveVar(leadVar);
  const {updateScenario} = useUpdateScenario();
  const {cookie} = useCookies(
    getKeyWithUUID(CONTRACTOR_CASE_CREATED_KEY, lead.id),
    false,
  );
  const {cookie: depreciatedCookie} = useCookies(DEPRECIATED_COOKIE_KEY, false);
  const caseCreated =
    cookie || depreciatedCookie || lead?.contractorRequestedAt;

  const [openProjectDetails, setOpenProjectDetails] = useState(false);

  useEffect(() => sendEvent('Dashboard/Find-A-Contractor-Page'), []);

  function onSubmit() {
    webToCase(lead);

    const contractorRequestedAt = new Date().toISOString();
    setLead({contractorRequestedAt});
    updateScenario({
      variables: {id: lead.id, contractorRequestedAt},
    });
    setOpenProjectDetails(true);

    sendEvent('Dashboard/Find-A-Contractor-Clicked');
  }

  return (
    <Page showFooterLinks>
      <Title textAlign="center" css={{fontSize: 40, lineHeight: 'normal'}}>
        Get matched to a vetted RenoFi contractor
      </Title>
      <Panel contentCss={{padding: '40px 0 27px 0'}}>
        <Flex paddingX={[20, 33]} flexDirection="column">
          {!caseCreated && (
            <FindContractorCTA zipCode={lead.zipCode} onSubmit={onSubmit} />
          )}
          {caseCreated && <FindContractorRequested />}
        </Flex>
        <ContractorStatus />

        <BasicProjectDetailsModal
          show={openProjectDetails}
          onClose={() => setOpenProjectDetails(false)}
        />
      </Panel>
      <Faqs faqs={faqs} />
    </Page>
  );
};

export default FindContractor;

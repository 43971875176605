import {Image} from 'rebass';

import {NavigationLinkMobile, NavigationItem, NavigationIcon} from './styled';

const AdditionalOption = ({active, onClick, icon, label}) => {
  return (
    <NavigationLinkMobile active={active} onClick={onClick}>
      <NavigationItem>
        <NavigationIcon>
          <Image src={icon} width="30px" />
        </NavigationIcon>
        {label}
      </NavigationItem>
    </NavigationLinkMobile>
  );
};

export default AdditionalOption;

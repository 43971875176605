import {useEffect} from 'react';

import {useReactiveVar} from '@apollo/client';

import {isLeadTurndownPartnerMatch} from '@renofi/utils/src/turndowns';
import {sendEvent} from '@renofi/analytics';
import {isLeadQualified, getEligibilityWarning} from '@renofi/utils';

import {loanProductsVar, leadVar} from '../api/cache';

const useLoanOptionsTracking = () => {
  const loanProducts = useReactiveVar(loanProductsVar) || [];
  const lead = useReactiveVar(leadVar);

  useEffect(() => {
    const shouldSendLoanOptionsEvents =
      lead?.id && !isLeadTurndownPartnerMatch(lead, loanProducts);

    if (shouldSendLoanOptionsEvents) {
      sendEvent('Dashboard/Loan-Options', {
        warning: getEligibilityWarning(lead),
      });
      if (!loanProducts.length) {
        sendEvent('Dashboard/Loan-Options/No-Products');
      } else {
        const simplifiedLoanProducts = loanProducts.reduce(
          (acc, {name}) => ({
            ...acc,
            ['Saw product ' + name]: true,
          }),
          {},
        );

        const simplifiedLoanProductsList = loanProducts
          ?.map(({name}) => name)
          .join(', ');

        sendEvent('Dashboard/Loan-Options/Products-Matched', {
          numberOfProducts: loanProducts.length || 0,
          qualified: isLeadQualified(lead),
          ...simplifiedLoanProducts,
          simplifiedLoanProductsList,
        });
      }
    }
  }, [loanProducts.length, lead?.id]);
};

export default useLoanOptionsTracking;

import {Image, Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {Button} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import closeIcon from './img/close.svg';
import {ReactComponent as RenoFiDesignLogo} from './img/logo-renofi-design.svg';
import designVisual from './img/renofi-design-visual.png';
import {CardWrapper, CardTitle, InnerPaddedWrapper} from './styled';

const RenoFiDesignCard = ({onClose = () => ({}), id}) => {
  const history = useHistory();
  const {isMobile} = useScreenSize();

  return (
    <CardWrapper>
      {isMobile && (
        <Image
          data-testid="renofi-design-close-btn"
          onClick={onClose}
          alignSelf="end"
          marginRight={2}
          height={20}
          src={closeIcon}
        />
      )}
      <InnerPaddedWrapper>
        <RenoFiDesignLogo />
        <CardTitle>Kick start your renovation</CardTitle>
      </InnerPaddedWrapper>
      <Image src={designVisual} width="100%" marginY={17} />
      <InnerPaddedWrapper style={{gap: 20}} lineHeight="19px">
        <Text>
          Get conceptual designs and cost estimates for your renovation project.
        </Text>
        <Text fontWeight={700}>Get started with a free consultation</Text>
        <Button
          onClick={() => {
            sendEvent('Dashboard/RenoFi-Design-CTA-Clicked');
            onClose();
            history.push(`/${id}/design`);
          }}
          secondary
          small>
          Find out more
        </Button>
      </InnerPaddedWrapper>
    </CardWrapper>
  );
};

export default RenoFiDesignCard;

import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {Button} from '@renofi/components';

import AdvisorThumb from '../../AdvisorThumb';

const ScheduleButton = ({onClick, small, ...props}) => {
  return (
    <Button
      width={small ? 194 : undefined}
      minWidth={small ? 194 : undefined}
      small={small}
      {...props}
      onClick={onClick}>
      <Flex alignItems="center" mt="-2px">
        <AdvisorThumb />
        <Text ml={12}>Schedule a call</Text>
      </Flex>
    </Button>
  );
};

ScheduleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ScheduleButton;

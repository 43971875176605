import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const ValueItemWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    position: 'relative',
    marginRight: '3%',
    minWidth: 130,
    justifyContent: 'space-between',
  }),
  ({mb}) =>
    mediaQuery({
      marginBottom: mb,
    }),
);

import {useEffect, useState} from 'react';

import {Box, Flex} from 'rebass';

import {CurrencyField, Label, Button} from '@renofi/components';
import {useUpdateScenario} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import {leadVar, setLead} from '../../api/cache';
import {Panel} from '../../components';

import Tooltip from './Tooltip';
import {PanelWarning, FormWrapper, InputWrapper} from './styled';

const LendingTreeForm = () => {
  const lead = leadVar();
  const {updateScenario} = useUpdateScenario();
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const isFormValid = Boolean(
    formData.renovationCost &&
      formData.afterRenovatedValue &&
      formData.annualIncomePretax,
  );

  useEffect(() => {
    sendEvent('Dashboard/Lending-Tree-Form-Shown');
  }, []);

  const onSubmit = async () => {
    setSubmitting(true);
    await updateScenario({variables: {id: lead.id, ...formData}});
    setSubmitting(false);
    setLead(formData);
    sendEvent('Dashboard/Lending-Tree-Form-Submitted', formData);
  };

  return (
    <Box marginTop={32}>
      <PanelWarning>
        ⚠️ <strong>Your loan options are preliminary.</strong> To update your
        loan options, please complete the missing information below.
      </PanelWarning>
      <Panel
        css={{
          boxShadow: 'none!important',
          marginTop: 0,
          borderTopLeftRadius: '0!important',
          borderTopRightRadius: '0!important',
        }}>
        <FormWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="renovationCost" small>
                Estimated renovation costs
              </Label>
              <Tooltip title="Estimated renovation costs">
                This is the estimated total cost of the project. For example, if
                the cost of a renovation is $200,000 but you’re only looking to
                borrow $120,000 put the total cost or - $200,000.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="renovationCost"
              icon
              value={formData.renovationCost}
              onChange={(renovationCost) =>
                setFormData({...formData, renovationCost})
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="afterRenovatedValue" small>
                Expected after renovation value
              </Label>
              <Tooltip title="Expected after renovation value">
                This is the estimated value of the property after the project
                has been completed.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="afterRenovatedValue"
              icon
              value={formData.afterRenovatedValue}
              onChange={(afterRenovatedValue) =>
                setFormData({...formData, afterRenovatedValue})
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Flex>
              <Label htmlFor="annualIncomePretax" small>
                Annual gross household income before tax
              </Label>
              <Tooltip title="Annual gross household income before tax">
                This is the combined gross cash income of all applicants who are
                members of the household. For example, if Mary and Joe married
                and Mary earns $50,000 while Joe earns $45,000, their combined
                pre-tax household income is $95,000.
              </Tooltip>
            </Flex>
            <CurrencyField
              id="annualIncomePretax"
              icon
              value={formData.annualIncomePretax}
              onChange={(annualIncomePretax) =>
                setFormData({...formData, annualIncomePretax})
              }
            />
          </InputWrapper>
          <InputWrapper css={{alignSelf: 'flex-end'}}>
            <Button
              disabled={!isFormValid || submitting}
              loading={submitting}
              onClick={onSubmit}>
              Update my loan options
            </Button>
          </InputWrapper>
        </FormWrapper>
      </Panel>
    </Box>
  );
};

export default LendingTreeForm;

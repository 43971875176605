import {Flex, Box, Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {isEmpty, isNil} from 'lodash';

import {
  SkeletonFlex,
  SkeletonText,
  LicenseIcon,
  InsuranceIcon,
  ReferencesIcon,
  BbbIcon,
} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';
import {formatAddress} from '@renofi/utils/src/format';

import {contractorVar} from '../../api/cache';
import {Grid} from '../styled';

import DetailBox from './DetalBox';

const bbbGradesEnum = [
  'a',
  'a_minus',
  'a_plus',
  'b',
  'b_minus',
  'b_plus',
  'c',
  'c_minus',
  'c_plus',
  'd',
  'd_minus',
  'd_plus',
  'f',
];

const ContractorsDetails = ({loading}) => {
  const contractor = useReactiveVar(contractorVar);
  const data = loading ? {} : contractor;
  const isContractorLoaded = !isEmpty(contractor) && !loading;

  if (isNil(data)) return null;

  function getBbbGradeValue() {
    if (!data?.bbbGrade) return data?.bbbGrade;
    return bbbGradesEnum.includes(data?.bbbGrade) ? 'Listed' : false;
  }

  return (
    <Box mt={36}>
      <SkeletonText
        animate={loading}
        skeletonWidth={160}
        mb="8px"
        fontSize={24}
        fontWeight={800}>
        {data?.businessName}
      </SkeletonText>

      <SkeletonText
        animate={loading}
        skeletonHeight={12}
        skeletonWidth={190}
        mb={32}
        color={gray}>
        {isContractorLoaded ? formatAddress(data) : undefined}
      </SkeletonText>

      <Flex mt={36} flexDirection={['column', 'row']}>
        <Box flex={4}>
          {isContractorLoaded ? (
            <Text color={gray}>
              {data?.businessName} has completed projects with RenoFi in the
              past. If you decide to work with this contractor, there’s a chance
              that your processing times will be significantly shorter.
              Information is as of the last review date and may not reflect
              current status.
            </Text>
          ) : (
            <SkeletonFlex animate={loading} skeletonHeight={12} count={9} />
          )}
        </Box>

        <Box flex={6} pl={[0, 36]} mt={[36, 0]}>
          <Grid maxWidth={540} data-testid="details-box">
            <DetailBox
              title="License"
              icon={LicenseIcon}
              value={data?.licenseScore}
            />
            <DetailBox
              title="Insurance"
              icon={InsuranceIcon}
              value={data?.insuranceScore}
            />
            <DetailBox
              title="References"
              icon={ReferencesIcon}
              value={data?.referencesScore}
            />

            <DetailBox title="BBB" icon={BbbIcon} value={getBbbGradeValue()} />
          </Grid>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContractorsDetails;

import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

import {Small} from '../../components';

export const SmallLink = styled(Small)(
  mediaQuery({
    color: '#3786FD',
    lineHeight: '10px',
    cursor: 'pointer',
    fontSize: 12,
    borderBottom: 'solid 1px #3786FD',
    ':hover': {
      borderBottom: 'solid 1px transparent',
    },
  }),
);

import {PencilIcon} from '@renofi/components';
import {formatPhoneNumber} from '@renofi/utils/src/format';

import {LeadDetailsStatement, EditButton} from './styled';

const EditLeadDetails = ({lead, onEdit}) => {
  const phone = formatPhoneNumber(lead?.phone);
  const name = `${lead?.firstName}${lead?.lastName ? ' ' + lead.lastName : ''}`;
  const email = lead?.email;

  return (
    <LeadDetailsStatement>
      {name} {phone ? `| ${phone}` : ''} {email ? `| ${email}` : ''}
      <EditButton onClick={onEdit}>
        <PencilIcon />
      </EditButton>
    </LeadDetailsStatement>
  );
};

export default EditLeadDetails;

import React, {useEffect} from 'react';

import {Flex, Image} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {Button, Faqs, Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {ScheduledCallAlert} from '@renofi/modules';

import useSchedule from '../hooks/useSchedule';
import useModals, {MODAL_TYPES} from '../hooks/useModals';
import {Page} from '../components';

import {ReactComponent as DesignLogoIcon} from './img/design-logo.svg';
import monetizationIcon from './img/monetization.png';
import lightningIcon from './img/lightning.png';
import checkIcon from './img/check.png';
import coreyHannah from './img/corey-hannah.png';
import kristen from './img/kristen.png';
import faqs from './faqs';
import {
  Panel,
  Hero,
  HeroHeading,
  HeroDescription,
  BenefitTitle,
  BenefitDescription,
  BenefitContainer,
  ButtonContainer,
  Note,
  KristenImage,
  CoreyHannahImage,
} from './styled';

const Design = () => {
  const {isCallScheduled, callDetails} = useSchedule('design');
  const {setModal} = useModals();
  const {isDesktop} = useScreenSize();
  useEffect(() => sendEvent('Dashboard/Design-Page'), []);

  return (
    <Page showFooterLinks>
      <Panel css={{marginTop: 0}} contentCss={{padding: '0'}}>
        <Hero>
          <DesignLogoIcon />
          <HeroHeading>Get Help Planning Your Renovation</HeroHeading>
          <HeroDescription>
            Work with a professional designer over Zoom and get 3D designs and
            cost estimates.
          </HeroDescription>
          {isDesktop && <KristenImage src={kristen} />}
          {isDesktop && <CoreyHannahImage src={coreyHannah} />}
        </Hero>
        <Flex flexDirection={['column', 'row']} width="100%">
          <BenefitContainer width={[1, 1 / 3]} p={'20px'}>
            <Image src={monetizationIcon} />
            <BenefitTitle>Professional Designs</BenefitTitle>
            <BenefitDescription>
              Professional designs at a fraction of the cost of Traditional
              Architects and Designers.
            </BenefitDescription>
          </BenefitContainer>
          <BenefitContainer width={[1, 1 / 3]} p={'20px'}>
            <Image src={lightningIcon} />
            <BenefitTitle>Quick Turnaround</BenefitTitle>
            <BenefitDescription>
              From initial kick-off call to contractor bids in weeks rather than
              months when compared with traditional methods.
            </BenefitDescription>
          </BenefitContainer>
          <BenefitContainer width={[1, 1 / 3]} p={'20px'}>
            <Image src={checkIcon} />
            <BenefitTitle>Cost Estimate Included</BenefitTitle>
            <BenefitDescription>
              Enter Contractor negotiations with drawings, cost estimates and
              confidence.
            </BenefitDescription>
          </BenefitContainer>
        </Flex>
        <ButtonContainer>
          <Toggle show={isCallScheduled}>
            <ScheduledCallAlert
              callDetails={callDetails}
              advisor="Renofi Designer"
            />
          </Toggle>
          <Toggle show={!isCallScheduled}>
            <Button onClick={() => setModal(MODAL_TYPES.DESIGN_LEAD_DETAILS)}>
              Book a free consultation
            </Button>
          </Toggle>
        </ButtonContainer>
      </Panel>
      <Note>
        RenoFi Designs is suitable for most renovation projects where spatial
        planning is required. It is not suitable for ground up builds or pool
        installations.
      </Note>
      <Faqs faqs={faqs} />
    </Page>
  );
};

export default Design;

import styled from '@emotion/styled/macro';
import {Box, Text, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Panel as PanelComponent} from '@renofi/components';

export const Panel = styled(PanelComponent)(
  mediaQuery({
    boxShadow: 'none !important',
    backgroundColor: ['transparent', 'white'],
  }),
);

export const Hero = styled.div(
  mediaQuery({
    backgroundImage: [
      'url(/img/design-hero-mobile.png)',
      'url(/img/design-hero.jpg)',
    ],
    borderTopLeftRadius: [0, '16px'],
    borderTopRightRadius: [0, '16px'],
    width: '100%',
    minHeight: ['488px', '392px'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    position: 'relative',
    padding: ['20px', '40px 60px'],
  }),
);

export const HeroHeading = styled(Text)(
  mediaQuery({
    fontSize: '40px',
    lineHeight: ['48px', '56px'],
    color: '#fff',
    width: ['100%', '40%'],
    paddingTop: '30px',
  }),
);

export const HeroDescription = styled(Text)(
  mediaQuery({
    fontSize: '22px',
    lineHeight: '32px',
    color: '#fff',
    width: ['100%', '45%'],
    padding: ['20px 0', '30px 0'],
  }),
);

export const BenefitContainer = styled(Box)(
  mediaQuery({
    padding: '40px',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '16px',
    margin: ['15px 0 0 0', '0'],
  }),
);

export const BenefitTitle = styled(Text)(
  mediaQuery({
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FF5253',
    padding: '20px 0',
  }),
);

export const BenefitDescription = styled(Text)(
  mediaQuery({
    fontSize: '16px',
    lineHeight: '20px',
  }),
);

export const ButtonContainer = styled(Box)(
  mediaQuery({
    padding: ['20px 0 0 0', '0 0 60px 0'],
    textAlign: 'center',
  }),
);

export const Note = styled(Text)(
  mediaQuery({
    padding: ['30px 50px', '30px 100px'],
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#006099',
  }),
);

export const CoreyHannahImage = styled(Image)(
  mediaQuery({
    position: 'absolute',
    bottom: '40px',
    right: '300px',
    width: '350px',
  }),
);

export const KristenImage = styled(Image)(
  mediaQuery({
    position: 'absolute',
    width: '180px',
    top: '40px',
    right: '40px',
  }),
);

export const RenovationPackageItem = styled(Box)(
  mediaQuery({
    height: '408px',
    borderRadius: '16px',
    border: '1px solid #D8D8D8',
    textAlign: 'center',
    margin: ['5px 0', '0 8px'],
  }),
);

export const RenovationPackageItemTitle = styled(Text)(
  mediaQuery({
    color: '#FF5253',
    fontSize: '20px',
    lineHeight: '24px',
    padding: '16px',
  }),
);

export const RenovationPackageItemDescription = styled(Text)(
  mediaQuery({
    color: '#737373',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '16px',
  }),
);

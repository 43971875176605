import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REINVITE_BORROWER_TO_PROJECT_BY_SCENARIO} from '../mutations/reinviteBorrowerToProjectByScenario';

export default function useReinviteBorrowerToProjectByScenario({
  onCompleted = noop,
} = {}) {
  const [reinviteBorrowerToProjectByScenario, {data, loading, error}] =
    useMutation(REINVITE_BORROWER_TO_PROJECT_BY_SCENARIO, {
      onCompleted,
    });
  const response = data?.reinviteBorrowerToProjectByScenario;
  return {reinviteBorrowerToProjectByScenario, response, loading, error};
}

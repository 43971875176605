export default [
  {
    question: 'How does this work?',
    answer:
      'A RenoFi representative will contact you to discuss your project and match you with a vetted contractor.',
    open: false,
  },
  {
    question: 'What is the RenoFi contractor vetting process?',
    answer:
      'Contractor vetting is our process of conducting due diligence and verifying the contractors who work on projects financed with a RenoFi loan. RenoFi collects documents and references from homeowners and contractors to ensure the selected contractor is qualified to complete the project. RenoFi protects the homeowner and projects that fail contractor due diligence aren’t able to move forward and get funded with a RenoFi loan.',
    open: false,
  },
  {
    question: 'Is there a fee for this service?',
    answer:
      'No, this service is 100% free of charge to both - the homeowner and the contractor.',
    open: false,
  },
  {
    question:
      'To use this service, do I have to also use a RenoFi Loan for my project?',
    answer:
      'No, there is no obligation to use RenoFi to finance your project if we have helped you find a contractor. If you have decided to work with a RenoFi-vetted contractor, the process of securing a RenoFi loan may be significantly lighter.\n.',
    open: false,
  },
];

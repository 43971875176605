import {Flex, Image} from 'rebass';

import {
  RenovationPackageItem,
  RenovationPackageItemTitle,
  RenovationPackageItemDescription,
} from './styled';
import conceptReviewCall from './img/concept-review-call.png';
import finalPDFPackage from './img/final-pdf-package.png';
import projectEvaluationCall from './img/project-evaluation-call.png';

const RenofiDesignPackage = () => {
  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        margin={[0, '0 20px']}>
        <RenovationPackageItem width={[1, 1 / 3]}>
          <RenovationPackageItemTitle>
            Project evaluation call
          </RenovationPackageItemTitle>
          <Image src={projectEvaluationCall} />
          <RenovationPackageItemDescription>
            One of our designers will{' '}
            <strong>evaluate your existing space</strong>, its limitations, and
            potential before{' '}
            <strong>brainstorming initial design concepts</strong>.
          </RenovationPackageItemDescription>
        </RenovationPackageItem>
        <RenovationPackageItem width={[1, 1 / 3]}>
          <RenovationPackageItemTitle>
            Concept review call
          </RenovationPackageItemTitle>
          <Image src={conceptReviewCall} />
          <RenovationPackageItemDescription>
            On the second call your designer will{' '}
            <strong>present up to three design concepts</strong> for review and
            discussion.
          </RenovationPackageItemDescription>
        </RenovationPackageItem>
        <RenovationPackageItem width={[1, 1 / 3]}>
          <RenovationPackageItemTitle>
            The final PDF package{' '}
          </RenovationPackageItemTitle>
          <Image src={finalPDFPackage} />
          <RenovationPackageItemDescription>
            This contains{' '}
            <strong>3D conceptual designs of your renovation</strong> and a
            corresponding <strong>cost estimate</strong>, which you can use to
            request bids from contractors.
          </RenovationPackageItemDescription>
        </RenovationPackageItem>
      </Flex>
    </>
  );
};

export default RenofiDesignPackage;

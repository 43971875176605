import React, {useState} from 'react';

import {Box, Text, Image} from 'rebass';
import {useHistory} from 'react-router-dom';

import {ClickOutside, Toggle} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import {logout, redirectToLogin} from '../../utils';
import useModals, {MODAL_TYPES} from '../../hooks/useModals';

import AdditionalOption from './AdditionalOption';
import useAvailableNavOptions from './useAvailableNavOptions';
import {
  NavbarWrapperMobile,
  BottomNavExtension,
  BottomNavMobile,
  BottomNavOption,
} from './styled';
import CompanyLogos from './CompanyLogos';

const NavbarMobile = ({
  loading,
  id: leadId,
  navMainOptions,
  navAdditionalOptions,
}) => {
  const history = useHistory();
  const {setModal} = useModals();
  const {CONTACT, LOGOUT, LOGIN, TOGGLE_MORE} = useAvailableNavOptions();
  const [moreOptionsToggled, setMoreOptionsToggled] = useState(false);
  const [outsideMoreOptionsClicked, setOutsideMoreOptionsClicked] =
    useState(false);

  const bottomNavOptionActive = (optionId, optionPath) => {
    if (moreOptionsToggled) {
      return optionId === TOGGLE_MORE.id ? true : false;
    }
    return history.location.pathname.includes(optionPath);
  };

  function onClickOutside() {
    setMoreOptionsToggled(false);
    setOutsideMoreOptionsClicked(true);
    setTimeout(() => setOutsideMoreOptionsClicked(false), 100);
  }

  return (
    <>
      <NavbarWrapperMobile>
        <CompanyLogos />
      </NavbarWrapperMobile>
      <Toggle show={moreOptionsToggled}>
        <BottomNavExtension>
          <ClickOutside onClickOutside={onClickOutside}>
            <Box backgroundColor={'white'}>
              {navAdditionalOptions.map((option, index) => (
                <AdditionalOption
                  key={index}
                  active={history.location.pathname.includes(option.path)}
                  onClick={() => {
                    if (option.id === LOGOUT.id) {
                      logout();
                      return;
                    }
                    if (option.id === LOGIN.id) {
                      redirectToLogin();
                      return;
                    }
                    setMoreOptionsToggled(false);
                    sendEvent(option.event);
                    history.push(`/${leadId}/${option.path}`);
                  }}
                  icon={option.icon}
                  label={option.label}
                />
              ))}
            </Box>
          </ClickOutside>
        </BottomNavExtension>
      </Toggle>
      <BottomNavMobile>
        <Toggle show={!loading}>
          {navMainOptions.map((option, index) => (
            <BottomNavOption
              key={index}
              active={bottomNavOptionActive(option.id, option.path)}
              onClick={() => {
                setOutsideMoreOptionsClicked(false);
                sendEvent(option.event);
                if (option.id === CONTACT.id) {
                  setModal(MODAL_TYPES.SCHEDULE);
                  return;
                }
                if (option.id === TOGGLE_MORE.id) {
                  if (!moreOptionsToggled && !outsideMoreOptionsClicked) {
                    setMoreOptionsToggled(true);
                  }
                } else {
                  setMoreOptionsToggled(false);
                  history.push(`/${leadId}/${option.path}`);
                }
              }}>
              <Image src={option.icon} width="30px" />
              <Text fontSize={11}>{option.label}</Text>
            </BottomNavOption>
          ))}
        </Toggle>
      </BottomNavMobile>
    </>
  );
};

export default NavbarMobile;

import React, {useEffect, useState} from 'react';

import {Box, Flex, Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {ScheduleButton, Toggle} from '@renofi/components';
import {useProductMatchesForEstimate} from '@renofi/api';
import {
  ProductOptions,
  FailureTitle,
  ScheduleBar,
  ScheduledCallSuccess,
  ProductCarousel,
} from '@renofi/modules';
import {sendEvent} from '@renofi/analytics';
import {
  isLeadQualified,
  useFeatureFlagVariantEnabled,
  isUncompletedLendingTreeLead,
  isContractorIntroLeadIncomplete,
} from '@renofi/utils';
import {isFakeLead} from '@renofi/utils/src/lead';

import {useLoanOptionsTracking} from '../hooks';
import {Page, Title} from '../components';
import {leadVar, loanProductsVar} from '../api/cache';
import useModals, {MODAL_TYPES} from '../hooks/useModals';
import AdvisorCard from '../components/AdvisorCard';

import EditDetailsModal from './EditDetailsModal';
import LoanValuesPanel from './LoanValuesPanel';
import {LendingTreeForm, ContractorIntroLeadForm} from './MissingDetailsForm/';
import PrequalificationLetterPanel from './PrequalificationLetterPanel';

const LoanOptions = ({scheduleUtils}) => {
  const [showModal, setShowModal] = useState(false);
  const lead = useReactiveVar(leadVar);
  const isUncompletedLendingTree = isUncompletedLendingTreeLead(lead);
  const isContractorIntroIncomplete = isContractorIntroLeadIncomplete(lead);
  const loanProducts = useReactiveVar(loanProductsVar);
  const {productMatches} = useProductMatchesForEstimate({
    skip: !(isContractorIntroIncomplete && lead.zipCode && lead.renovationCost),
    variables: {
      zipCode: lead.zipCode,
      loanAmount: lead.renovationCost,
      estimatedHomeValue: lead.homeValue,
    },
  });

  const isQualified = isLeadQualified(lead);
  const {setModal} = useModals();
  const hasOptions = isContractorIntroIncomplete
    ? Boolean(productMatches?.length)
    : Boolean(loanProducts?.length);

  const {callDetails, isCallScheduled} = scheduleUtils;

  const fakeLead = isFakeLead(lead);

  useLoanOptionsTracking();

  const isPrequalificationExperimentEnabled = useFeatureFlagVariantEnabled(
    'dashboard-prequalification-letter',
  );

  const showPrequalificationFeatures =
    isPrequalificationExperimentEnabled &&
    !isCallScheduled &&
    !isUncompletedLendingTree &&
    !isContractorIntroIncomplete &&
    isQualified;

  useEffect(() => {
    if (!lead.id) return;

    sendEvent('Dashboard/Loan-Options-Page', {
      subcontractorsSecured: lead.subcontractorsSecured,
    });
  }, [lead.id]);

  const onEdit = () => {
    sendEvent('Dashboard/Loan-Options-Edit-Button-Clicked');
    setShowModal(true);
  };

  useEffect(() => {
    if (fakeLead && loanProducts.length) {
      setModal(MODAL_TYPES.LEAD_DETAILS);
    }
  }, [fakeLead]);

  return (
    <>
      <Page loading={!lead.firstName} showOptionsDisclaimer>
        <Toggle show={Boolean(loanProducts.length)}>
          <Title>Loan options</Title>
        </Toggle>

        <Toggle show={isUncompletedLendingTree}>
          <LendingTreeForm />
        </Toggle>

        <Toggle show={isContractorIntroIncomplete}>
          <ContractorIntroLeadForm />
        </Toggle>

        <Toggle
          show={!isUncompletedLendingTree && !isContractorIntroIncomplete}>
          <LoanValuesPanel lead={lead} onEdit={onEdit} />
        </Toggle>

        <Box mt={[16, 28]}>
          <Toggle show={isCallScheduled}>
            <ScheduledCallSuccess
              callDetails={callDetails}
              rescheduleEnabled
              onReschedule={() => setModal(MODAL_TYPES.SCHEDULE)}
            />
          </Toggle>
          <Toggle
            show={
              !isUncompletedLendingTree &&
              !isContractorIntroIncomplete &&
              !isPrequalificationExperimentEnabled &&
              !isCallScheduled &&
              isQualified
            }>
            <ScheduleBar card={<AdvisorCard />}>
              <Flex justifyContent={'center'}>
                <ScheduleButton
                  data-testid="schedule-button"
                  analyticsPrefix="Dashboard"
                  text="NO"
                  icon={null}
                  css={{
                    marginBottom: 0,
                    padding: '0 40px 0 40px',
                    height: '45px',
                  }}
                  secondary
                  readyToSchedule="no"
                  onClick={() => setModal(MODAL_TYPES.NOT_READY)}
                />
                <ScheduleButton
                  analyticsPrefix="Dashboard"
                  text="YES"
                  icon={null}
                  css={{
                    marginBottom: 0,
                    marginLeft: '12px',
                    padding: '0 50px 0 50px',
                    height: '45px',
                  }}
                  readyToSchedule="yes"
                  onClick={() => setModal(MODAL_TYPES.READY)}
                />
              </Flex>
            </ScheduleBar>
          </Toggle>
          <Toggle show={!isContractorIntroIncomplete}>
            <Text fontSize={32} lineHeight="42px">
              <FailureTitle isNarrow={false} lead={lead} hasContactUsLink />
            </Text>
          </Toggle>

          <Toggle show={!isQualified && !isContractorIntroIncomplete}>
            <Box backgroundColor={'#fff'} mt={30} pt={64}>
              <ProductCarousel lead={lead} />
            </Box>
          </Toggle>

          <Toggle show={hasOptions}>
            <ProductOptions
              lead={lead}
              options={
                isContractorIntroIncomplete ? productMatches : loanProducts
              }
            />
          </Toggle>

          <Toggle show={showPrequalificationFeatures}>
            <PrequalificationLetterPanel />
          </Toggle>
        </Box>
      </Page>

      <EditDetailsModal
        onClose={() => setShowModal(false)}
        show={showModal}
        id={lead.id}
      />
    </>
  );
};

export default LoanOptions;

import {Flex, Box} from 'rebass';

import {SkeletonText} from '@renofi/components';
import SkeletonFlex from '@renofi/components/src/SkeletonText/SkeletonFlex';

import {Page} from '../components';

const LoanOptionsSkeleton = () => {
  const LoanOptionSkeleton = (
    <>
      <SkeletonFlex
        flex={1}
        style={{marginBottom: 8}}
        animate
        count={1}
        skeletonHeight={100}
      />
      <SkeletonFlex
        flex={1}
        style={{marginBottom: 8}}
        animate
        count={6}
        skeletonHeight={24}
      />

      <SkeletonFlex
        flex={1}
        style={{marginBottom: 8}}
        animate
        count={1}
        skeletonHeight={100}
      />
    </>
  );

  return (
    <Page showOptionsDisclaimer>
      <SkeletonText skeletonWidth="50%" skeletonHeight={40} pb={30} />
      <SkeletonText skeletonHeight={60} pb={10} />
      <SkeletonFlex
        style={{marginBottom: 10}}
        animate
        count={3}
        skeletonHeight={30}
        pb={36}
      />

      <Flex width="100%" flexDirection={['column', 'row']}>
        <Box flex={1} pr={[0, 24]} pb={[36, 0]}>
          {LoanOptionSkeleton}
        </Box>
        <Box flex={1} pr={[0, 24]} display={['none', 'block']}>
          {LoanOptionSkeleton}
        </Box>
        <Box flex={1} display={['none', 'block']} pb={64}>
          {LoanOptionSkeleton}
        </Box>
      </Flex>
    </Page>
  );
};

export default LoanOptionsSkeleton;

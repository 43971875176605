import PropTypes from 'prop-types';
import {Text, Flex} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {InfoTooltip, Loader, PanelDark} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {ValueItemWrapper} from './styled';

const ValueItem = ({loading, value, title, mb, tooltipText}) => {
  if (!value) return null;

  return (
    <ValueItemWrapper mb={mb}>
      <Flex>
        <Text
          color={gray}
          css={{position: 'relative'}}
          lineHeight="18px"
          mb="2px">
          {title}
        </Text>
        {tooltipText ? (
          <InfoTooltip
            dark
            id={title}
            delayHide={0}
            css={{
              height: 24,
              position: 'static',
              paddingLeft: 16,
              paddingTop: 2,
            }}>
            <PanelDark title={title}>{tooltipText}</PanelDark>
          </InfoTooltip>
        ) : null}
      </Flex>

      <Text fontSize={24}>
        {loading ? (
          <Loader mt={0} color={gray} />
        ) : (
          <strong>${toCurrency(value)}</strong>
        )}
      </Text>
    </ValueItemWrapper>
  );
};

ValueItem.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
  title: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default ValueItem;

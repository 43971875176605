import {Flex, Box, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {Button} from '@renofi/components';
import {AssistantsStaticWidget as Assistants} from '@renofi/components';
import {CheckOutlinedIcon} from '@renofi/components';

import {setModal} from '../../api/cache';
import {MODAL_TYPES} from '../../hooks/useModals';
import {Panel, PanelTitle} from '../../components';

import {BenefitContainer, Benefit, ContentWrapper} from './styled';

const PrequalificationLetterPanel = () => {
  return (
    <Panel>
      <PanelTitle large>You are 90% pre-qualified!</PanelTitle>
      <ContentWrapper>
        <Box>
          <Text fontSize={18} color="#737373" maxWidth={590}>
            Schedule a 15 minute call with a RenoFi Advisor to complete your
            prequalification or simply ask questions.
          </Text>
          <BenefitContainer>
            <Flex alignItems="center">
              <CheckOutlinedIcon />
              <Benefit>No commitment</Benefit>
            </Flex>
            <Flex alignItems="center">
              <CheckOutlinedIcon />
              <Benefit>No impact on your credit score</Benefit>
            </Flex>
            <Flex alignItems="center">
              <CheckOutlinedIcon />
              <Benefit>Quick & easy</Benefit>
            </Flex>
          </BenefitContainer>
        </Box>
        <Box margin="auto">
          <Assistants sm />
        </Box>
      </ContentWrapper>
      <Flex css={{gap: 16}} marginTop={24}>
        <Button
          css={{
            marginBottom: 0,
            padding: '0 40px 0 40px',
            height: '45px',
          }}
          onClick={() => {
            sendEvent('Dashboard/Request-Prequal-Button-Click', {
              requestPrequal: 'request',
            });
            setModal(MODAL_TYPES.REQUEST_PREQUALIFICATION);
          }}>
          Complete prequal
        </Button>
      </Flex>
    </Panel>
  );
};

export default PrequalificationLetterPanel;

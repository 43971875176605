import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {capitalizeFirstLetter, toCurrency} from '@renofi/utils/src/format';
import {calculateTotalMortgageBalance, loanTerms} from '@renofi/utils';
import {InfoTooltip, PanelDark, Toggle} from '@renofi/components';
import {darkGreen} from '@renofi/utils/src/colors';
import {isLendingTreeLead} from '@renofi/utils/src/lead';

import {
  OptionWrapper,
  OptionRow,
  OptionHeader,
  OptionItemText,
  Small,
} from '../styled';
import MonthlyPayment from '../MonthlyPayment';
import {CASH_DISBURSEMENT_TYPE} from '../consts';
import {PRODUCT_TITLES} from '../utils';
import ProductCombinations from '../ProductCombinations';

const OptionSetItemWrapper = ({children}) => (
  <Flex width="100%" alignItems="baseline">
    {children}
  </Flex>
);

const config = {
  default: {
    amountTile: 'Loan amount',
    amountNote: '',
    paymentsNote: '',
  },
  construction_loan: {
    amountTile: 'Amount for build',
    paymentsNote: 'on build amount',
  },
  purchase_and_renovate_loan: {
    amountTile: 'Renovation amount',
    paymentsNote: 'on renovation amount',
  },
  renofi_cashout_refi: {
    amountTile: 'Cash out amount',
    paymentsNote: 'on cash out amount',
  },
  renovation_mortgage: {
    amountTile: 'Renovation amount ',
    paymentsNote: 'on renovation amount',
  },
  traditional_cashout_refi: {
    amountTile: 'Cash out amount',
    paymentsNote: 'on cash out amount',
  },
};

export function getProductConfig(option) {
  return {...config.default, ...(config[option.productType] || {})};
}

export function getProductTitlePrefix(option, loanAmount) {
  const isLoanAmountAvailable =
    option.offeredLoanAmountExcludingRefinance >= loanAmount;
  return isLoanAmountAvailable ? '' : 'estimated max ';
}

export function getAmountTitle(prefix, productConfig) {
  return capitalizeFirstLetter(
    `${prefix}${productConfig.amountTile}`.toLowerCase(),
  );
}

const Option = ({qualified, option, lead, options}) => {
  const {state, loanAmountByProducts} = lead;
  option = {
    ...option,
    ...option.product,
  };
  delete option.product;
  const {0: firstRate = 0} = option?.rates || [];
  const productConfig = getProductConfig(option);
  const isCashout = option?.productType?.includes('cashout');
  const isRefinance = option.refinancedMonthlyMortgagePayments > 0;
  const isLendingTree = isLendingTreeLead(lead);
  const refinanceRequiredMortgageBased = option?.lender?.code === 'afr';
  const prefix = getProductTitlePrefix(option, loanAmountByProducts);
  const amountTitle = getAmountTitle(prefix, productConfig);
  const refinanceRequired = refinanceRequiredMortgageBased
    ? Boolean(calculateTotalMortgageBalance(lead))
    : option.refinanceRequired;
  if (!option.productType && option.name?.toLowerCase() === 'renofi heloc') {
    option.productType = 'renofi_heloc';
  }
  const title = PRODUCT_TITLES[option.productType]
    ? PRODUCT_TITLES[option.productType].title
    : option.name;
  const hasAnyCombinations = options.some(
    (product) => product.allowedCombinations?.length,
  );
  const hasPurchaseLoans = options.some((product) =>
    product.productType?.includes('purchase'),
  );
  const isPurchaseAndRenovate =
    option.productType === 'purchase_and_renovate_loan';

  if (option.maxLoanAmountOffered <= 10000) return null;
  return (
    <OptionWrapper qualified={qualified}>
      <OptionHeader large={hasAnyCombinations && hasPurchaseLoans}>
        <h1>{title}</h1>
        <Toggle show={isPurchaseAndRenovate}>
          <Small textAlign="center" mt="8px">
            Finance the purchase and renovation in one single mortgage.
          </Small>
        </Toggle>
        <ProductCombinations title={title} option={option} options={options} />
      </OptionHeader>

      <OptionRow tinted>
        <OptionItemText>{amountTitle}</OptionItemText>
        <OptionItemText bold>
          <div>
            $
            {toCurrency(
              option.offeredLoanAmountExcludingRefinance ||
                option.maxLoanAmountOffered,
            )}
          </div>
        </OptionItemText>
      </OptionRow>
      {Boolean(firstRate) && (
        <OptionRow tinted>
          <OptionItemText>Rates as low as</OptionItemText>
          <OptionItemText justifyContent="flex-start" bold>
            <Flex flexDirection="column" alignItems="flex-start">
              {firstRate}% APR
              <Flex alignItems="center" css={{position: 'relative'}}>
                <Text color={darkGreen} fontSize={12} lineHeight="20px" pr={28}>
                  Competitive rate
                </Text>
                <InfoTooltip
                  id={option.id}
                  delayHide={0}
                  css={{fontWeight: 400, top: 0}}
                  width={256}>
                  <PanelDark title="Competitive rate">
                    We estimate that this is a competitive rate based on current
                    market environment.
                  </PanelDark>
                </InfoTooltip>
              </Flex>
            </Flex>
          </OptionItemText>
        </OptionRow>
      )}
      <OptionRow tinted>
        <OptionItemText>Payments as low as</OptionItemText>
        <OptionItemText justifyContent="flex-start" bold>
          <>
            <Flex flexDirection="column" alignItems="flex-start">
              <MonthlyPayment
                note={productConfig.paymentsNote}
                state={state}
                isRefinance={isRefinance}
                isCashout={isCashout}
                option={option}
              />
            </Flex>
          </>
        </OptionItemText>
      </OptionRow>
      <OptionRow padded>
        <Toggle show={Boolean(option.maxLoanAmount)}>
          <OptionSetItemWrapper>
            <OptionItemText>Borrow up to:</OptionItemText>
            <OptionItemText bold small>
              ${toCurrency(option.maxLoanAmount)}
            </OptionItemText>
          </OptionSetItemWrapper>
        </Toggle>
        <OptionSetItemWrapper>
          <OptionItemText>Terms up to:</OptionItemText>
          <OptionItemText bold small>
            {loanTerms(option.drawPeriod, option.repaymentPeriod)} years
          </OptionItemText>
        </OptionSetItemWrapper>
        <OptionSetItemWrapper>
          <OptionItemText>Rate type:</OptionItemText>
          <OptionItemText bold small>
            {capitalizeFirstLetter(option.rateType?.replace('-', ' '))}
          </OptionItemText>
        </OptionSetItemWrapper>

        <Toggle show={!isLendingTree}>
          <OptionSetItemWrapper>
            <OptionItemText>Prepayment penalties:</OptionItemText>
            <OptionItemText bold small>
              {option.repaymentPenalty ? 'Yes' : 'No'}
            </OptionItemText>
          </OptionSetItemWrapper>

          <OptionSetItemWrapper>
            <OptionItemText>Refinancing required:</OptionItemText>
            <OptionItemText bold small>
              {refinanceRequired ? 'Yes' : 'No'}
            </OptionItemText>
          </OptionSetItemWrapper>
        </Toggle>

        <OptionSetItemWrapper>
          <OptionItemText italic css={{marginTop: 16}}>
            {CASH_DISBURSEMENT_TYPE[option.cashDisbursementType]}
          </OptionItemText>
        </OptionSetItemWrapper>
      </OptionRow>
    </OptionWrapper>
  );
};

Option.propTypes = {
  option: PropTypes.object.isRequired,
  options: PropTypes.array,
  lead: PropTypes.object.isRequired,
  qualified: PropTypes.bool,
};

export default Option;

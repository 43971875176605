import {useEffect} from 'react';

import {isQueryParamSet} from '@renofi/utils';

import useModals, {MODAL_TYPES} from './useModals';

const useScheduleCalendar = (lead) => {
  const {setModal} = useModals();
  const isAutoScheduleStorageSet = JSON.parse(
    window.localStorage.getItem('renofi:schedule'),
  );
  const isScheduleParamSet = isQueryParamSet('schedule');
  useEffect(() => {
    if (
      (isAutoScheduleStorageSet || isScheduleParamSet) &&
      lead?.id &&
      lead?.prequalCompletedAt
    ) {
      setModal(MODAL_TYPES.SCHEDULE);
      window.localStorage.removeItem('renofi:schedule');
    }
  }, [lead?.id]);
};

export default useScheduleCalendar;

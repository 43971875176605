import React from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';

import {Alert} from '@renofi/components';

import {List} from '../styled';

const ContractorEmpty = ({businessName}) => {
  return (
    <>
      <Text fontSize={[20, 24]} mt={48} mb={24} lineHeight={['28px', '32px']}>
        <strong>
          Looks like {businessName} hasn't previously completed RenoFi’s due
          diligence process. If you apply for a RenoFi Loan, we will perform
          contractor due diligence process to:
        </strong>
      </Text>

      <Alert bgColor="#E7F9E9" color="#0B7517" border="1px solid #13C427">
        <List>
          <li>
            Check if the contractor is suitable for your project to set you up
            for success.
          </li>
          <li>
            Inspect plans, costs and time schedule to ensure the project is
            feasible at the stated budget.
          </li>
          <li>
            Rigorously vet the contractor and check all required licenses and
            insurances.
          </li>
        </List>
      </Alert>
    </>
  );
};

ContractorEmpty.propTypes = {
  businessName: PropTypes.string.isRequired,
};

export default ContractorEmpty;

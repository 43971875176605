import {noop} from 'lodash';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';
import {LeadDetailsModal, LeadDetailsIncompleteModal} from '@renofi/modules';
import {isAuthenticated, savePersonalLeadData} from '@renofi/utils/src/lead';
import {useUpdateScenarioPersonal} from '@renofi/api';

import {setLead} from '../api/cache';

const LeadDetails = ({
  visible,
  lead,
  title,
  subtitle = '',
  designLead,
  fakeLead,
  cta,
  onSubmit = noop,
  onCancel = noop,
  onClose = noop,
}) => {
  const {updateScenarioPersonal, loading} = useUpdateScenarioPersonal({
    id: lead?.id,
  });

  const handleClose = () => {
    sendEvent('Dashboard/Personal-Details-Modal-Closed');
    onClose();
  };

  const handleSubmit = async (leadDetails) => {
    savePersonalLeadData(leadDetails, lead?.id);

    if (isAuthenticated(lead?.id)) {
      await updateScenarioPersonal({variables: {...leadDetails, id: lead?.id}});
    }

    setTimeout(() => {
      setLead(leadDetails);

      sendEvent('Dashboard/Personal-Details-Changed', {
        changedDetails: Object.keys(leadDetails).filter(
          (k) => lead[k] !== leadDetails[k],
        ),
      });
      onSubmit(leadDetails);
    }, 1000);
  };

  if (fakeLead) {
    return (
      <LeadDetailsIncompleteModal
        loading={loading}
        lead={lead}
        visible={visible}
        title={title}
        subtitle={subtitle}
        designLead={designLead}
        cta={cta}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onCancel={onCancel}
      />
    );
  }

  return (
    <LeadDetailsModal
      loading={loading}
      lead={lead}
      visible={visible}
      title={title}
      subtitle={subtitle}
      designLead={designLead}
      cta={cta}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onCancel={onCancel}
    />
  );
};

LeadDetails.propTypes = {
  lead: PropTypes.object,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default LeadDetails;

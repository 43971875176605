import {Text, Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {ScheduledCallAlert} from '../ScheduledCallAlert';

const ScheduledCallSuccess = (props) => (
  <Flex flexDirection="column" alignItems="center">
    <Text fontSize={28} lineHeight="40px" mb={32}>
      Thanks for scheduling the call! A RenoFi Advisor will help you understand
      what you need to get started!
    </Text>
    <Box width="100%">
      <ScheduledCallAlert {...props} />
    </Box>
  </Flex>
);

ScheduledCallSuccess.propTypes = {
  callDetails: PropTypes.object,
  rescheduleEnabled: PropTypes.bool,
  onReschedule: PropTypes.func,
};

export default ScheduledCallSuccess;

import React from 'react';

import {useReactiveVar} from '@apollo/client';

import {renofiLogoDarkIconSrc} from '@renofi/components';
import {useContractorBySlug} from '@renofi/api';

import {leadVar} from '../../api/cache';

import {GcLogo, Logo, TopBar} from './styled';

const CompanyLogos = () => {
  const lead = useReactiveVar(leadVar);
  const {utmMedium, utmSource} = lead || {};
  const isGcPartnerSource = Boolean(utmSource && utmMedium === 'partner-site');
  const {contractor} = useContractorBySlug({
    skip: !isGcPartnerSource,
    variables: {urlSlug: utmSource},
  });

  return (
    <TopBar>
      <Logo src={renofiLogoDarkIconSrc} alt="RenoFi" />
      {Boolean(contractor) ? (
        <GcLogo
          role="gc_logo"
          src={contractor?.logoUrl}
          alt={contractor?.businessName}
        />
      ) : null}
    </TopBar>
  );
};

export default CompanyLogos;

import {noop} from 'lodash';
import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import FeedbackPopup from './FeedbackPopup';

const FeedbackModal = ({
  visible,
  title = 'Please let us know what’s preventing you from scheduling a call today?',
  subtitle,
  options,
  onSubmit = noop,
  onClose = noop,
}) => {
  const handleClose = () => {
    sendEvent('Feedback-Modal-Closed');
    onClose();
  };

  const handleSubmit = (
    closeCalendarFeedback,
    closeCalendarFeedbackDetails,
  ) => {
    onSubmit({
      closeCalendarFeedback,
      closeCalendarFeedbackDetails,
    });
  };

  return (
    <FeedbackPopup
      title={title}
      subtitle={subtitle}
      optionsList={options}
      visible={visible}
      onFeedbackSubmit={handleSubmit}
      onFeedbackClose={handleClose}
    />
  );
};

FeedbackModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FeedbackModal;

import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

import {ReactComponent as ComingSoonIcon} from './img/coming-soon.svg';

export const ComingSoonSticker = styled(ComingSoonIcon)(() =>
  mediaQuery({
    position: 'absolute',
    right: -6,
    top: -33,
  }),
);

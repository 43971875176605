import {InfoTooltip, PanelDark, InfoIcon} from '@renofi/components';

const Tooltip = ({title, children}) => (
  <InfoTooltip
    id={title}
    delayHide={0}
    css={{
      height: 24,
      position: 'static',
      paddingLeft: 16,
      paddingTop: 2,
    }}
    customIcon={<InfoIcon />}>
    <PanelDark title={title}>{children}</PanelDark>
  </InfoTooltip>
);

export default Tooltip;

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {Label, TextField, SelectField} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {forEachStateMap} from '@renofi/api';

const MARGIN_TOP = 26;

function AddressInputs({
  onChange,
  zipCode,
  city,
  county,
  streetAddress,
  state,
  loading,
}) {
  const {isMobile} = useScreenSize();
  const commonProps = {
    required: true,
    small: true,
    noSpecialCharacters: true,
  };
  const inputDisabled = loading;

  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        width={['100%', 'auto']}
        alignItems="flex-end">
        <Box mr={[0, 16]} flex={3} width={['100%', 'auto']}>
          <Label small htmlFor="streetAddress" mt={MARGIN_TOP}>
            Street address
          </Label>
          <TextField
            disabled={inputDisabled}
            value={streetAddress}
            onChange={(value, err) => onChange('streetAddress', value, err)}
            id="streetAddress"
            name="streetAddress"
            type="text"
            {...commonProps}
          />
        </Box>
        <Box mr={[0, 16]} flex={2} width={['100%', 'auto']}>
          <Label small htmlFor="state" mt={MARGIN_TOP}>
            State
          </Label>
          <SelectField
            disabled={inputDisabled}
            autoComplete="false"
            value={state}
            onChange={(value, err) => onChange('state', value, err)}
            id="state"
            name="state"
            {...commonProps}>
            {<option />}
            {forEachStateMap((key, value) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </SelectField>
        </Box>

        <Box mr={[0, 16]} flex={1.3} width={['100%', 'auto']}>
          <Label small htmlFor="zipCode" mt={MARGIN_TOP}>
            ZIP Code
          </Label>
          <TextField
            disabled={inputDisabled}
            value={zipCode}
            onChange={(value, err) => onChange('zipCode', value, err)}
            id="zipCode"
            name="zipCode"
            digitsOnly
            limit={5}
            min={5}
            inputmode="numeric"
            pattern={isMobile ? '[0-9]*' : undefined}
            {...commonProps}
          />
        </Box>
        <Box mr={[0, 16]} flex={2} width={['100%', 'auto']}>
          <Label small htmlFor="city" mt={MARGIN_TOP}>
            City
          </Label>
          <TextField
            disabled={inputDisabled}
            noNumbers
            value={city}
            onChange={(value, err) => onChange('city', value, err)}
            id="city"
            name="city"
            type="text"
            {...commonProps}
          />
        </Box>

        <Box mr={[0, 16]} flex={2} width={['100%', 'auto']}>
          <Label small htmlFor="county" mt={MARGIN_TOP}>
            County
          </Label>
          <TextField
            disabled={inputDisabled}
            noNumbers
            value={county}
            onChange={(value, err) => onChange('county', value, err)}
            id="county"
            name="county"
            type="text"
            {...commonProps}
          />
        </Box>
      </Flex>
    </>
  );
}

AddressInputs.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  streetAddress: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AddressInputs;

import {Flex} from 'rebass';

import {Button, Toggle} from '@renofi/components';
import {useRenovationUtils} from '@renofi/utils';

import {Panel, ValueItem} from '../components';

const LoanValuesPanel = ({lead, onEdit}) => {
  const {isHomeBuild} = useRenovationUtils(lead);
  const commonProps = {
    mb: [24, 0],
    small: true,
  };

  return (
    <Panel
      id="loan-values-panel"
      action={
        <Button onClick={onEdit} secondary xxSmall>
          Edit
        </Button>
      }>
      <Flex flexDirection={['column', 'row']} pr={70}>
        <ValueItem
          {...commonProps}
          title={isHomeBuild ? 'Build cost' : 'Renovation Cost'}
          value={lead.renovationCost}
        />

        <ValueItem
          {...commonProps}
          title="Estimated purchase price"
          value={lead.propertyPurchasePrice}
        />

        <Toggle show={isHomeBuild}>
          <ValueItem
            {...commonProps}
            title="Estimated current home value"
            value={lead.estimatedHomeValue || lead.homeValue}
          />
        </Toggle>

        <Toggle show={!isHomeBuild}>
          <ValueItem
            {...commonProps}
            title="Current home value"
            value={lead.homeValue}
          />
        </Toggle>

        <ValueItem
          {...commonProps}
          title="Estimated downpayment"
          value={lead.estimatedDownpayment}
        />

        <ValueItem
          {...commonProps}
          title={
            isHomeBuild
              ? 'Estimated first mortgage balance'
              : 'First mortgage balance'
          }
          value={lead.firstMortgageBalance}
        />

        <ValueItem
          small
          title={
            isHomeBuild ? 'Value upon completion' : 'After renovation value'
          }
          value={lead.afterRenovatedValue}
        />
      </Flex>
    </Panel>
  );
};

export default LoanValuesPanel;

import useToggledQuery from '../../useToggledQuery';
import {GET_PRODUCT_MATCHES_FOR_ESTIMATE} from '../queries';

export default function useProductMatchesForEstimate({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
}) {
  const {fetch, data, loading} = useToggledQuery(
    GET_PRODUCT_MATCHES_FOR_ESTIMATE,
    {
      lazy,
      fetchPolicy,
      ...options,
    },
  );

  const productMatches = data?.productMatchesForEstimate || [];

  return {fetch, loading, productMatches};
}

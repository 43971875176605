import styled from '@emotion/styled/macro';
import {Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkGreen} from '@renofi/utils/src/colors';

export const BenefitContainer = styled(Flex)(
  mediaQuery({
    gap: [10, 24],
    flexDirection: ['column', 'row'],
    marginTop: 16,
  }),
);

export const Benefit = styled(Text)(() => ({
  color: darkGreen,
  marginLeft: '8px',
  fontSize: '18px',
}));

export const ContentWrapper = styled(Flex)(
  mediaQuery({
    alignItems: ['center'],
    gap: [24, 138],
    flexDirection: ['column', 'row'],
  }),
);

import React, {useEffect} from 'react';

import {Text, Link} from 'rebass';
import PropTypes from 'prop-types';

import {enums} from '@renofi/api';
import {getEligibilityWarning, ELIGIBILITY_WARNINGS} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {Narrow} from '../styled';

const FailureTitle = ({lead = {}, isNarrow = true, hasContactUsLink}) => {
  const {
    firstName,
    propertyStructureType,
    realCreditScoreNumber,
    creditScore,
    state,
  } = lead;
  const warningID = getEligibilityWarning(lead)?.id;

  const WelcomeToApply = () =>
    hasContactUsLink ? (
      <Link
        style={{fontSize: 'inherit', textDecoration: 'none'}}
        href="https://renofi.com/contact/">
        welcome to apply
      </Link>
    ) : (
      'welcome to apply'
    );

  useEffect(() => {
    if (warningID) {
      sendEvent('Dashboard/Loan-Options-Warning-Message', {
        warningMessage: warningID,
      });
    }
  }, [warningID]);

  if (!warningID) return null;
  if (warningID === ELIGIBILITY_WARNINGS.LOW_CREDIT_SCORE.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, based on the information you provided and a{' '}
          <strong>
            credit score of {realCreditScoreNumber || enums.get(creditScore)}
          </strong>{' '}
          a lender <strong>may not approve</strong> your application for a
          RenoFi Loan, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.RECENT_BANKRUPTCY.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, based on the information you provided, a lender{' '}
          <strong>may not approve</strong> your application for a RenoFi Loan,
          due to your recent bankruptcy, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.ESTIMATED_DTI.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, based on the information you provided, a lender{' '}
          <strong>may not approve</strong> your application for a RenoFi Loan,
          due to your estimated DTI, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.HAS_LATE_MORTGAGE_PAYMENTS.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, based on the information you provided, a lender{' '}
          <strong>may not approve</strong> your application for a RenoFi Loan,
          due to late mortgage payments, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.SMALL_AMOUNT.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, there are no lenders to apply with at this time
          offering RenoFi Loans for this amount.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.STATE.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, there are no lenders to apply with at this time
          offering RenoFi Loans in {state}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.USE_TYPE.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, there are no lenders to apply with at this time
          offering RenoFi Loans for investment properties.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.STRUCTURE_TYPE.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, there are no lenders to apply with at this time
          offering RenoFi Loans for {enums.get(propertyStructureType)}{' '}
          properties.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.PERFORMER.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, lenders <strong>are unlikely to approve</strong> your
          application for a RenoFi Loan if you&apos;re doing the entire
          renovation by yourself.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.DTI.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, based on the information provided you have a high DTI
          Ratio. A lender <strong>may not approve</strong> your application for
          a RenoFi Loan, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.PCLTV.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, based on the information provided you have a high LTV
          Ratio. A lender <strong>may not approve</strong> your application for
          a RenoFi Loan, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  if (warningID === ELIGIBILITY_WARNINGS.ACLTV.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          Unfortunately, based on the information provided you have a high LTV
          Ratio. A lender <strong>may not approve</strong> your application for
          a RenoFi Loan, but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }
  // default message if no other one applies
  if (warningID === ELIGIBILITY_WARNINGS.UNQUALIFIED.id) {
    return (
      <Narrow isNarrow={isNarrow} maxWidth={890}>
        <Text>
          {firstName}, based on the information you provided a lender
          <strong> may not approve</strong> your application for a RenoFi Loan,
          but you&apos;re {<WelcomeToApply />}.
        </Text>
      </Narrow>
    );
  }

  return null;
};

FailureTitle.propTypes = {
  lead: PropTypes.object,
  isNarrow: PropTypes.bool,
};

export default FailureTitle;

import React from 'react';

import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {toCurrency, loanTerms} from '@renofi/utils';

import {ACCORDION_TYPE, CASH_DISBURSEMENT_TYPE} from '../index';
import {OptionItem} from '../Option';
import MonthlyPayment from '../MonthlyPayment';
import {GCOption, GCOptionTitleText} from '../styled';
import ProductCombinations from '../ProductCombinations';
import {
  getAmountTitle,
  getProductConfig,
  getProductTitlePrefix,
} from '../Option/Option';

function GCLeadOption({option, options, lead}) {
  const productConfig = getProductConfig(option);
  const {isLowCreditScore, inLicensedState, state, loanAmountByProducts} = lead;
  const {0: firstRate} = option.rates;
  const isCashout = option?.productType.includes('cashout');
  const isRefinance = option.refinancedMonthlyMortgagePayments > 0;
  const prefix = getProductTitlePrefix(option, loanAmountByProducts);
  const amountTitle = getAmountTitle(prefix, productConfig);

  if (option.maxLoanAmountOffered <= 10000) return null;

  return (
    <GCOption>
      <Flex
        height="100%"
        flexWrap="wrap"
        textAlign="left"
        backgroundColor="white"
        py={24}
        px={24}
        maxWidth={['100%', 380]}>
        <Box width={1} textAlign="center" pb="40px" pt="15px">
          <GCOptionTitleText>{option.name}</GCOptionTitleText>
          <ProductCombinations
            title={option.name}
            option={option}
            options={options}
          />
        </Box>
        <Flex flexDirection="column" justifyContent="flex-end" width="100%">
          {isLowCreditScore && (
            <OptionItem
              label="Minimum required credit score"
              value={option.minCreditScore}
            />
          )}

          <OptionItem label={amountTitle} />
          <OptionItem label="rates as low as" value={`${firstRate}% APR`} />

          <OptionItem label="Payments as low as">
            <MonthlyPayment
              note={productConfig.paymentsNote}
              isRefinance={isRefinance}
              state={state}
              isCashout={isCashout}
              option={option}
            />
          </OptionItem>

          <OptionItem
            label={ACCORDION_TYPE.AMOUNT}
            value={`$${toCurrency(option.maxLoanAmount)}`}
          />
          <OptionItem
            label={ACCORDION_TYPE.TERMS}
            value={`${loanTerms(option.drawPeriod, option.repaymentPeriod)} years`}
          />
          <OptionItem label={ACCORDION_TYPE.RATE} value={option.rateType} />
          <OptionItem
            label={ACCORDION_TYPE.PREPAYMENT}
            value={option.repaymentPenalty ? 'Yes' : 'No'}
          />
          <OptionItem
            label={ACCORDION_TYPE.REFINANCING}
            value={option.refinanceRequired ? 'Yes' : 'No'}
          />
          <OptionItem
            label={ACCORDION_TYPE.DISBURSEMENT}
            value={CASH_DISBURSEMENT_TYPE[option.cashDisbursementType]}
          />
          {!inLicensedState && option?.lender?.name && (
            <OptionItem
              label={ACCORDION_TYPE.LENDER}
              value={option.lender.name}
            />
          )}
        </Flex>
      </Flex>
    </GCOption>
  );
}

GCLeadOption.propTypes = {
  option: PropTypes.object,
  options: PropTypes.array,
  lead: PropTypes.object.isRequired,
};

export default GCLeadOption;

const CheckOutlined = ({
  size = 25,
  fill = 'none',
  checkColor = '#13C427',
  outlineColor = '#13C427',
}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 24 25"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.5" r="10.5" stroke={outlineColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 16.5L7 13L8.41 11.59L10.5 13.67L15.68 8.5L17.09 9.91L10.5 16.5Z"
      fill={checkColor}
    />
    <mask
      id="mask0_6732_153769"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="7"
      y="8"
      width="11"
      height="9">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 16.5L7 13L8.41 11.59L10.5 13.67L15.68 8.5L17.09 9.91L10.5 16.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_6732_153769)"></g>
  </svg>
);

export default CheckOutlined;

import {isEmpty} from 'lodash';

export function mapLoanPassProduct(products = []) {
  if (isEmpty(products)) return products;

  return products.map(
    ({
      productType,
      loanAmount,
      estimatedApr,
      estimatedPayment,
      interestOnlyPeriod,
      loanTermMonths,
      amortizationType,
    }) => ({
      offeredLoanAmountExcludingRefinance: loanAmount,
      renovationFractionMinMonthlyPayment: estimatedPayment,
      product: {
        name: productType,
        productType: productType,
        rates: [estimatedApr],
        interestOnly: interestOnlyPeriod,
        repaymentPeriod: loanTermMonths / 12,
        rateType: amortizationType,
        repaymentPenalty: null,
        refinanceRequired: null,
      },
    }),
  );
}

import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export default styled(Text)(({css, lineHeight, mb, large}) =>
  mediaQuery({
    color: gray,
    fontSize: 20,
    lineHeight: lineHeight || '18px',
    marginBottom: mb || 32,
    ...(large
      ? {
          lineHeight: ['24px', '32px'],
          fontSize: [22, 28],
          fontWeight: 700,
          color: 'black',
          marginBottom: 24,
        }
      : {}),
    ...css,
  }),
);

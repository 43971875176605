import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export default styled(Text)(({css, fontWeight, lineHeight}) =>
  mediaQuery({
    fontSize: [28, 40],
    fontWeight: fontWeight || 800,
    lineHeight: lineHeight || ['32px', '48px'],
    ...css,
  }),
);

import {makeVar} from '@apollo/client';

// Reactive variables
export const crmOwnerVar = makeVar({});

// Setter
export const setCrmOwner = (crmOwner) => {
  crmOwnerVar({...crmOwnerVar(), ...crmOwner});
};

// Apollo field policy
export const crmOwnerPolicy = {
  read() {
    return crmOwnerVar();
  },
};

import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {Small} from '../styled';
import {findProductCombinations} from '../utils';

const ProductCombinations = ({option, options, title}) => {
  if (options?.length < 2) return null;

  const combinations = findProductCombinations(option, options);

  if (!combinations?.length) return null;

  return (
    <Box>
      <Small textAlign="center" mt="8px">
        Can be combined with a{` `}
        {combinations?.map((type, index) => {
          return (
            <Box as="span" key={type.title}>
              {index === 0 ? type.title : <> and {type.title}</>}
            </Box>
          );
        })}
        . Option below only reflects the {title}
      </Small>
    </Box>
  );
};

ProductCombinations.propTypes = {
  option: PropTypes.object,
  options: PropTypes.array,
  title: PropTypes.node,
};

export default ProductCombinations;
